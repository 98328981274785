import {combineReducers} from 'redux';
import user from './user-reducer';
import applications from './applications-reducer';
import shortlist from './shortlisted-reducer'
import notifications from './notifications-reducer';
import recent from './recent-reducer'
/*
import media from './media-reducer';
import user from './user-reducer';
import dialog from './dialog-reducer';
import panel from './panel-reducer';
import panelType from './panelType-reducer';
import members from './members-reducer';
import history from './history-reducer';
import conferenceCall from './conferenceCall-reducer'
*/

const rootReducer = combineReducers({
    user,
    applications,
    shortlist,
    recent,
    notifications
});


export default rootReducer;
