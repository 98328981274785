import * as types from './action-types';


export const replaceRecent = ( recent ) => {
  return {
    type: types.RECENT_REPLACE_ALL,
    recent
  };
}
  export const updateStatus = ( data ) => {
    return {
      type: types.RECENT_UPDATE_STATUS,
      data
    }
  }

  export const addNewSubmission = ( submission_id ) => {
    return {
      type: types.RECENT_ADDED_NEW_SUBMISSION,
      submission_id
    }
  }
