import Storage from './Storage';
import System from './System';
import Broadcast from './Broadcast';
import User from './User';

export default class Globals {

    static Storage = new Storage();

    static System = new System();
    static Broadcast = new Broadcast();

   // static FIREBASE_HAS_ACCOUNT = "FirebaseHasAccount";

    static TOKEN_STORAGE_KEY = "bzToken";
    static START_PAGE_KEY = "bzRecStartPage"

    // max sizes for uploads in MB
    static MAX_UPLOAD_DOCUMENT_SIZE = 50; // 50mb

    static MAX_UPLOAD_IMAGE_SIZE = 12; // 12mb
    static MAX_UPLOAD_VIDEO_SIZE = 200; // 200 mb


    static FirebaseInstance = null;
    static FirebaseApp = null;

    static YouTubeReady = false;

    static onYouTubeIframeAPIReady() {
       // console.log( "onYouTubeIframeAPIReady ready" );
        Globals.YouTubeReady = true;
    }


    static CurrentUser = () => {
        return User.CurrentUser();
    }

    static SaveStartPage = ( page ) => {
        Globals.Storage.StoreLocal( Globals.START_PAGE_KEY, page );
    }

    static LoadStartPage = () => {
        let page =  Globals.Storage.LoadLocal( Globals.START_PAGE_KEY );
        if ( ! page ) return "dashboard"

        return page;
    }
    /*
    static QuickLogInCheck()
    {
        return Globals.Storage.LoadLocal( Globals.QUICK_LOGGED_IN_CHECK ) === "1";
    }

    static ClearQuickLogInCheck()
    {
        Globals.Storage.RemoveLocal( Globals.QUICK_LOGGED_IN_CHECK );
    }

    static SetQuickLogInCheck()
    {
        Globals.Storage.StoreLocal( Globals.QUICK_LOGGED_IN_CHECK, "1" );
    }
    */
}
