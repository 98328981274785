import React from 'react';
import styles from '../../css/login_form.module.css'
import Spacer from '../ui/Spacer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Auth from '../classes/Auth';
import IOStatus from '../classes/IOStatus';
import { Checkbox} from 'pretty-checkbox-react';
import '../../css/pretty-checkbox.css'
import VerifyCodeForm from './VerifyCodeForm';
import Utils from '../classes/Utils';
import CreateAccountForm from './CreateAccountForm';
import ResetPasswordForm from './ResetPasswordForm'

export default class LogInForm extends React.Component {

    static LogInModeFull = 0;
    static LogInModeLogInOnly = 1;

    emailRef = null;
    passwordRef = null;
    keepLoggedInRef = null;

    constructor( props )
    {
        super(props);

        let mode = this.props.mode;
        if ( isNaN( mode ) )
        {
            mode = LogInForm.LogInModeFull;
        }

        let showCreateAccount = false;
        if (this.props.showCreateAccount === true )
        {
            showCreateAccount = true;
        }
        this.state = { logInMode: mode, showCreateAccountForm: showCreateAccount, showResetPasswordForm: false, email: "", password: "", buttonEnabled: true, emailErrorMsg: "", passwordErrorMsg: "", isLoggingIn: false, errorMsg: "", verificationRequired: false }

        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.keepLoggedInRef = React.createRef();
    }


    componentDidMount() {

        this.updateControls();
    }

    TextInputChanged = ( id, txt ) => {

        switch( id )
        {
            case "email":

                this.setState( { email: txt } );
                break;

            case "password":

                this.setState( { password: txt } );
                break;

            default: break;

        }
        this.updateControls();
    }

    TextInputKeyUp = ( id, keyCode ) => {
       // console.log( id, keyCode );
       //
       switch( id )
       {
           case "email":

                break;

            case "password":

                if ( keyCode === 13 )
                {
                    this.doLogIn();
                }
                break;

            default: break;
       }
    }

    CloseVerificationForm = () => {
        this.setState( { verificationRequired: false, isLoggingIn: false  } )
    }

    loggedIn = ( result ) => {

        if (  this.props.callback && this.props.callback.UserLoggedIn )
        {
            this.props.callback.UserLoggedIn( result, this.keepLoggedInRef.current.checked  );
        }
    }

    VerifiedEmail = ( result, keepLoggedIn ) => {
        if (  this.props.callback && this.props.callback.UserLoggedIn )
        {
            this.props.callback.UserLoggedIn( result, keepLoggedIn  );
        }
    }

    createAccount = () => {
        // Utils.NotImplemented();
        this.setState( {showCreateAccountForm: true } );
    }

    showLogInFromCreate = ( email, password ) => {
        this.setState( {showCreateAccountForm: false, email: Utils.EnsureString( email ), password: Utils.EnsureString( password ) } );
    }

    showLogInFromRecover = () => {
        this.setState( {showResetPasswordForm: false } );
    }

    forgotPassword = () => {
        // Utils.NotImplemented();
        this.setState( {showCreateAccountForm: false, showResetPasswordForm: true } );
    }

    doLogIn = async () => {


        let email = this.emailRef.current.GetText();
        if (! email || email.length === 0 )
        {
            this.setState( { emailErrorMsg: "Enter your email address" } );
            return;
        }

        let password = this.passwordRef.current.GetText();
        if (! password || password.length === 0 )
        {
            this.setState( { passwordErrorMsg: "Enter your password" } );
            return;
        }

        this.setState(  { isLoggingIn: true, errorMsg: "" } );

        let [status, response] = await Auth.Login( email, password )
        console.log( status, IOStatus.NOT_FOUND, response );
        switch ( status )
        {
            case IOStatus.OK:

                this.loggedIn( response );
                break;
/*
            case 404:
            case IOStatus.NOT_FOUND:

                this.setState(  { isLoggingIn: false , errorMsg: "Email or password not correct" } );
                break;
*/
            default:

                if ( response )
                {
                    switch( response.error )
                    {
                        case IOStatus.VERIFICATION_REQUIRED:
                            this.setState( { verificationRequired: true, isLoggingIn: false } )
                            break;

                        case IOStatus.NOT_FOUND:

                            this.setState(  { isLoggingIn: false , errorMsg: "Email or password not correct" } );
                            break;

                        default:

                            this.setState(  { isLoggingIn: false , errorMsg: "Error contacting server, please try again" } );
                            break;
                    }
                }
                else
                {
                    this.setState(  { isLoggingIn: false , errorMsg: "Error contacting server, please try again" } );
                }
                break;
        }
    }

    updateControls = () => {

/*
        let enabled = true;

        if ( this.state.email.length === 0 )
        {
            enabled = false;
        }

        if ( this.state.password.length === 0 )
        {
            enabled = false;
        }
*/
       // this.setState( { buttonEnabled: enabled } );
    }

    render() {


        if ( this.state.showCreateAccountForm )
        {
            return ( <CreateAccountForm callback={this} /> )
        }

        if ( this.state.showResetPasswordForm )
        {
            return ( <ResetPasswordForm callback={this} /> )
        }

        if ( this.state.verificationRequired )
        {
            let email = this.emailRef.current.GetText();
            let keepLoggedIn = this.keepLoggedInRef.current.checked;

            return  ( <VerifyCodeForm callback={this} email={email} keepLoggedIn={keepLoggedIn} /> );
        }


        let logInButtonCls = styles.button;
        if ( ! this.state.buttonEnabled )
        {
            logInButtonCls += " " + styles.disabled;
        }

        let emailLabel = "Email";
        let emailLabelCls = styles.inputLabel;

        if ( this.state.emailErrorMsg && this.state.emailErrorMsg.length > 0 )
        {
            emailLabel = this.state.emailErrorMsg;
            emailLabelCls += " " + styles.inputLabelError;
        }

        let passwordLabel = "Password";
        let passwordLabelCls = styles.inputLabel;

        if ( this.state.passwordErrorMsg && this.state.passwordErrorMsg.length > 0 )
        {
            passwordLabel = this.state.passwordErrorMsg;
            passwordLabelCls += " " + styles.inputLabelError;
        }

        let logInLabel = "SIGN IN";
        if ( this.state.isLoggingIn )
        {
            logInLabel = "Signing in..."
        }

        let infoCls = styles.info;
        let infoMsg = "Create and review job positions.";

        if ( this.state.errorMsg.length > 0 )
        {
            infoCls += " " + styles.error;
            infoMsg = this.state.errorMsg;
        }

        let showFooter = this.state.logInMode === LogInForm.LogInModeFull;

        return (

            <div className={styles.holder}>
                <div className={styles.background}>
                    <div className={styles.title}>Sign In</div>
                    <div className={infoCls}>{infoMsg}</div>
                    <Spacer height={10} />

                    <div className={emailLabelCls}>{emailLabel}</div>
                    <LogInFormInput defaultValue={this.state.email} ref={this.emailRef}  type="email" placeholder="Your work email address" id="email" callback={this} />
                    <div className={passwordLabelCls}>{passwordLabel}</div>
                    <LogInFormInput defaultValue={this.state.password} ref={this.passwordRef} type="password" placeholder="Password" id="password" showPassword={false} callback={this}  />
                    <Spacer height={25} />
                    <Checkbox  ref={this.keepLoggedInRef} animation="smooth"  color="success-o" style={{ fontSize: 14 }}>Keep me signed in</Checkbox>
                    <Spacer height={25} />
                    <div onClick={this.doLogIn} className={logInButtonCls}>{logInLabel}</div>

                </div>
                {showFooter && <div className={styles.footer}>
                    <div className={styles.button_quiet}  onClick={this.forgotPassword}>Forgot <span style={{textDecoration: "underline"}}>password</span>?</div>
                    <div className={styles.divider}>|</div>
                    <div className={styles.button_quiet} onClick={this.createAccount}>Create an <span style={{textDecoration: "underline"}}>account</span></div>
                </div>}
            </div>
        )
    }
}


export class LogInFormInput extends React.Component
{
    inputRef = null;

    constructor( props )
    {
        super( props );
        this.state = {type: this.props.type, showPassword: this.props.showPassword === true }

        this.inputRef = React.createRef();
    }


    componentDidMount() {
        if ( this.props.focus )
        {
            this.Focus();
        }
    }

    GetText = () => {
        return this.inputRef.current.value;
    }

    Focus = () => {
        try {
            this.inputRef.current.focus()
        } catch( e ) {}
    }


    textChanged = (e) => {
        if ( this.props.callback && this.props.callback.TextInputChanged )
        {
            this.props.callback.TextInputChanged( this.props.id,  e.target.value )
        }

    }

    keyUp = (e) => {
        if ( this.props.callback && this.props.callback.TextInputKeyUp )
        {
            this.props.callback.TextInputKeyUp( this.props.id,  e.keyCode )
        }
    }

    togglePassword = () => {
        this.setState( { showPassword: ! this.state.showPassword } )
    }

    render() {

        let cls = styles.input;
        let type = this.props.type;
        let showPasswordButton = false;
        let passwordIcon = faEye;
        let autocomplete="on";

        let isError = (this.props.errorMsg && this.props.errorMsg.length > 0);
        if ( this.props.type === "password" )
        {
            if ( this.props.newPassword )
            {
                autocomplete = "new-password";
            }
            else
            {
                autocomplete="current-password";
            }

            showPasswordButton = true;
            if ( this.state.showPassword )
            {
                type = "text";
                passwordIcon = faEyeSlash;
            }
            cls += " " + styles.password_input;
        }

        if ( isError )
        {
            cls += " " + styles.input_error;
        }


        return (
                <div style={{position: "relative"}}>
                    <input defaultValue={this.props.defaultValue} ref={this.inputRef} onKeyUp={this.keyUp} onChange={this.textChanged} className={cls} autoComplete={autocomplete} type={type} placeholder={this.props.placeholder} id={this.props.id} />
                    {showPasswordButton && <div onClick={this.togglePassword} className={styles.passwordButton}><FontAwesomeIcon icon={passwordIcon}></FontAwesomeIcon></div>}
                </div>
            )
        }
}