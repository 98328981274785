import * as React from "react";
const Delete = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_342_4315)">
      <path
        d="M4.65265 1.43951H4.49221C4.58045 1.43951 4.65265 1.36751 4.65265 1.27951V1.43951H10.7492V1.27951C10.7492 1.36751 10.8214 1.43951 10.9097 1.43951H10.7492V2.87951H12.1931V1.27951C12.1931 0.573512 11.6176 -0.000488281 10.9097 -0.000488281H4.49221C3.78429 -0.000488281 3.20872 0.573512 3.20872 1.27951V2.87951H4.65265V1.43951ZM14.7601 2.87951H0.641744C0.28678 2.87951 0 3.16551 0 3.51951V4.15951C0 4.24751 0.0721963 4.31951 0.160436 4.31951H1.37173L1.86708 14.7795C1.89916 15.4615 2.4647 15.9995 3.14856 15.9995H12.2533C12.9392 15.9995 13.5027 15.4635 13.5348 14.7795L14.0301 4.31951H15.2414C15.3297 4.31951 15.4019 4.24751 15.4019 4.15951V3.51951C15.4019 3.16551 15.1151 2.87951 14.7601 2.87951ZM12.0989 14.5595H3.30298L2.81766 4.31951H12.5842L12.0989 14.5595Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_342_4315">
        <rect
          width={16}
          height={16}
          fill="none"
          transform="translate(0 -0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Delete;
