import React from 'react';
import {ApplicationStatus} from '../classes/ApplicationsSummary'



export default class SubmissionStatusSlug extends React.Component
{

    UNREAD = "Unread"
    DECLINED = "Declined"
    INTERVIEWING = "Interview"
    SHORTLISTED = "Shortlisted";
    CLIENT_DECLINED = "Declined";
    CLIENT_ACCEPTED = "Accepted";
    CLIENT_NOT_RESPONDED = "No response"
    PENDING = "Pending";


    UNREAD_SHORT = "U";
    DECLINED_SHORT = "D"
    INTERVIEWING_SHORT = "I"
    SHORTLISTED_SHORT = "S";
    CLIENT_DECLINED_SHORT = "D";
    CLIENT_ACCEPTED_SHORT = "A";
    CLIENT_NOT_RESPONDED_SHORT = "N";
    PENDING_SHORT = "P";

    clicked = () => {
        if ( this.props.clickHandler )
        {
            this.props.clickHandler()
        }
    }

    render() {

        let label = "";
        // pass -1 to not show count
        if ( this.props.count >= 0 )
        {
            label = this.props.count + " ";
        }

        let cls = "submission_status_slug";
        let short = this.props.short === true;
        if ( short )
        {
            cls += " short ";
        }

        if ( this.props.inline )
        {
            cls += " inline ";
        }

        if ( this.props.size === "large" )
        {
            cls += " large ";
        }

        switch ( parseInt(this.props.type) )
        {
            case ApplicationStatus.UNREAD:

                cls += " " + this.UNREAD;
                short ? label += this.UNREAD_SHORT : label += this.UNREAD;
                break;

            case ApplicationStatus.PENDING:

                cls += " " + this.PENDING;
                short ? label += this.PENDING_SHORT : label += this.PENDING;
                break;

            case ApplicationStatus.DECLINED:
                cls += " " + this.DECLINED;
                short ? label += this.DECLINED_SHORT : label += this.DECLINED;
                break;

            case ApplicationStatus.INTERVIEWING:
                cls += " " + this.INTERVIEWING;
                short ? label += this.INTERVIEWING_SHORT : label += this.INTERVIEWING;
                break;

            case ApplicationStatus.SHORTLISTED:

                cls += " " + this.SHORTLISTED;
                if ( this.props.isClient === true )
                {
                    short ? label += this.CLIENT_NOT_RESPONDED_SHORT : label += this.CLIENT_NOT_RESPONDED;
                }
                else
                {
                    short ? label += this.SHORTLISTED_SHORT : label += this.SHORTLISTED;
                }
                break;

                case ApplicationStatus.CLIENT_DECLINED:

                    cls += " " + this.CLIENT_DECLINED;
                    short ? label += this.CLIENT_DECLINED_SHORT : label += this.CLIENT_DECLINED;
                    break;

                case ApplicationStatus.CLIENT_ACCEPTED:

                    cls += " " + this.CLIENT_ACCEPTED;
                    short ? label += this.CLIENT_ACCEPTED_SHORT : label += this.CLIENT_ACCEPTED;
                    break;

                default: break;
        }

        return (

            <div onClick={this.clicked} className={cls}>{label}</div>
        )
    }
}