import * as React from "react";
const CloseCross = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.30626 8.45596L15.7863 1.97596C15.9319 1.80592 16.008 1.58718 15.9993 1.36347C15.9907 1.13976 15.898 0.927545 15.7397 0.769239C15.5813 0.610933 15.3691 0.518192 15.1454 0.509551C14.9217 0.50091 14.703 0.577005 14.5329 0.722628L8.05293 7.20263L1.57293 0.713739C1.40288 0.568116 1.18415 0.492022 0.960436 0.500663C0.736724 0.509304 0.524512 0.602043 0.366205 0.76035C0.207899 0.918656 0.115159 1.13087 0.106518 1.35458C0.0978767 1.57829 0.173971 1.79703 0.319595 1.96707L6.79959 8.45596L0.310706 14.936C0.217655 15.0156 0.142082 15.1137 0.0887276 15.224C0.0353736 15.3343 0.00539082 15.4544 0.000662382 15.5768C-0.00406606 15.6992 0.0165614 15.8213 0.0612499 15.9354C0.105938 16.0494 0.173724 16.153 0.26035 16.2397C0.346976 16.3263 0.450574 16.3941 0.564641 16.4387C0.678708 16.4834 0.800781 16.5041 0.923198 16.4993C1.04561 16.4946 1.16573 16.4646 1.27601 16.4113C1.38629 16.3579 1.48435 16.2823 1.56404 16.1893L8.05293 9.70929L14.5329 16.1893C14.703 16.3349 14.9217 16.411 15.1454 16.4024C15.3691 16.3937 15.5813 16.301 15.7397 16.1427C15.898 15.9844 15.9907 15.7722 15.9993 15.5485C16.008 15.3247 15.9319 15.106 15.7863 14.936L9.30626 8.45596Z"
      fill="currentColor"
    />
  </svg>
);
export default CloseCross;
