import 'firebase/messaging';
import superagent from 'superagent';
import IOStatus from '../classes/IOStatus';
import Urls from '../classes/Urls';
import User from '../classes/User';
import { isMobile } from 'react-device-detect';
import Utils from '../classes/Utils';


export default class FirebaseMessaging {

    messaging = null;
    supported = false;
    registration = null;
    vapidKey = '';
    deviceToken = "";

    static PermissionNotSupported = "notSupported";
    static PermissionNotAsked = "notAsked";
    static PermissionGranted = "granted";
    static PermissionDenied = "denied";

    // only gets constructed if actually used
    constructor(firebase, vapidKey, startNotifications )
    {
     //  console.log( "consstr", firebase, vapidKey, startNotifications )
        this.vapidKey = vapidKey;

        if ( firebase && firebase.messaging && firebase.messaging.isSupported() )
        {
          this.registerWorker(firebase, startNotifications);
        }
    }

    /*
    regTest = async () => {

      let reg = await navigator.serviceWorker.register('./sw/telezing/firebase-messaging-sw.js');
      console.log( "reg2", reg );
    }
    */


    registerWorker = async (firebase, startNotifications ) => {

      this.registration = await navigator.serviceWorker.register('./sw/firebase-messaging-sw.js');
      if ( this.registration )
      {
        // console.log( "reg", this.registration );

        this.messaging = firebase.messaging();
        this.supported = true;

        if ( startNotifications )
        {
          this.StartPushNotifications();
        }
      }
    }


    UnregisterWorker = async () => {
      // console.log( "unregister" );

      let This = this;
      if ( ! this.registration )
      {
        this.registration = await navigator.serviceWorker.register('./sw/firebase-messaging-sw.js');
      }
      this.registration.unregister().then(function(boolean) {
        This.registration = null;
      });
    }

    StopPushNotifications = async () => {

      // console.log( "stop notifications", this.deviceToken, this.messaging, this.registration );

      // unsubscribes form all topics
      // this.SubscribeToNotifications( 0 );

      this.SaveDeviceToken( "" );

      if ( ! this.messaging ) {
        // registration may still be active from previous user
       // await this.UnregisterWorker();
        return false;
      }


      try {
      this.messaging.deleteToken( this.deviceToken ).then( async (success) => {
        // console.log( "stop", success )
        // await this.UnregisterWorker();
        return success;
      } );
    } catch( e )
    {
      // token may not still be valid.
      // console.log( "deleteToken", e );
      // await this.UnregisterWorker();
      return true;
    }

      // await this.UnregisterWorker();
      return true;
    }

    StartPushNotifications = async ( topics ) => {

       // console.log( "start not", this.registration, this.messaging,this.vapidKey );
        if ( ! this.registration ) return false;
        if ( ! this.messaging ) return false;
        if ( ! this.vapidKey || this.vapidKey.length === 0 ) return false;


          this.messaging.getToken({ vapidKey: this.vapidKey , serviceWorkerRegistration: this.registration  }).then((currentToken) => {

            //console.log( currentToken );
            this.deviceToken = currentToken;

            this.SaveDeviceToken(currentToken );

            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // ...

              try {
                //this.SubscribeToNotifications( topics );
              }
              catch( e )
              {
                console.log( e );
              }

              this.messaging.onMessage((payload) => {
                console.log('Message received. ', payload);
              //  eventBus.dispatch( eventBusEvents.USER_JOINED_NOTIFICATION, payload );
              });

              return true;
            }

            return false;

          });
    }

    SaveDeviceToken = ( token ) => {


      let fields = null;

      let user = User.CurrentUser();
      if ( isMobile )
      {
        if ( ! Utils.HasString( token ) )
        {
          fields = { "mobile" : "" }
        }
        else
        {
          if ( token !== user.MobileDeviceToken )
          {
            fields = { "mobile" : token }
          }
        }
      }
      else
      {
        if ( ! Utils.HasString( token ) )
        {
          fields = { "desktop" : "" }
        }
        else
        {
          if ( token !== user.MobileDeviceToken )
          {
            fields = { "desktop" : token }
          }
        }
      }

     // console.log( "save device token", token, user.DesktopDeviceToken  )

      if ( ! fields )
      {
        return;
      }



      let url = new Urls().UserSavePushTokenUrl();

      superagent.post( url )

        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('x-requested-with', 'XMLHttpRequest')
        .send( fields )
        .auth(user.UserToken, { "type": "bearer" } )

          .end((error, response) => {
            // console.log( error, response );
          } )
    }

    SubscribeToNotifications = ( topics ) => {

      let This = this;

      return new Promise((resolve,reject) => {

        if ( ! Utils.HasString( This.deviceToken ) ||  This.deviceToken == 0 )
        {
          // not registered. happens when signed out, can be ignored
          resolve( {successful: true, "code": IOStatus.OK } );
          return;
        }
        let url = new Urls().UserUpdatePushNotificationsUrl()
        let user = User.CurrentUser();

        let params = { deviceToken:  This.deviceToken, topics: topics, mobile: isMobile ? 1 : 0 }
        // console.log( "SubscribeToNotifications2", user )

        superagent.post( url )

          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('x-requested-with', 'XMLHttpRequest')
          .send( params )
          .auth(user.UserToken, { "type": "bearer" } )

            .end((error, response) => {

              // console.log( error, response );

              resolve( {successful: true, "code": IOStatus.OK } );
/*
               if ( error )
               {
                   if ( error.status )
                   {
                       if ( error.status === 401 )
                       {
                           // not suthorized to join
                           resolve( {successful: false, "code": IOStatus.NOT_AUTHORIZED, userId: 0 } )
                           return;
                       }

                       if ( error.status === 409 )
                       {
                           // already exists
                           resolve( {successful: false, "code": IOStatus.RESOURCE_EXISTS, userId: 0 } )
                           return;
                       }
                   }

                   // server error
                   reject({successful: false, "code": IOStatus.UNKNOWN_ERROR, userId: 0 } );
                   return;
               }

               if ( response && response.body && response.body.successful )
               {
                   resolve( {successful: true, "code": IOStatus.OK, userId: response.body.userId } );
                   return;
               }
               resolve({successful: false, "code": IOStatus.UNKNOWN_ERROR, userId: 0 } );
               */
             });

        })
    }

    IsSupported = () => {

        return this.supported;
    }

    static PermissionStatus = () => {

      if (!("Notification" in window))
      {
        return FirebaseMessaging.PermissionNotSupported;
      }

      if( window.Notification.permission === "granted" )
      {
        return FirebaseMessaging.PermissionGranted;
      }

      if( window.Notification.permission === "denied" )
      {
        return FirebaseMessaging.PermissionDenied;
      }

      return FirebaseMessaging.PermissionNotAsked;
    }

}


   /*
        if( Utils.DevMode() )
        {
          const messaging = firebase.messaging();
          messaging.getToken({ vapidKey: config.vapidKey }).then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // ...
              console.log( currentToken );

              messaging.onMessage((payload) => {
                console.log('Message received. ', payload);
                // ...
              });

            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
              if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
              }

              // Let's check whether notification permissions have already been granted
              else if (Notification.permission === "granted") {
                // If it's okay let's create a notification
                var notification = new Notification("Hi there!");
              }

              // Otherwise, we need to ask the user for permission
              else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                  // If the user accepts, let's create a notification
                  if (permission === "granted") {
                    var notification = new Notification("Hi there!");
                  }
                });
              }

          }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...

            if (!("Notification" in window)) {
              alert("This browser does not support desktop notification");
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
              // If it's okay let's create a notification
              var notification = new Notification("Hi there!");
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
              Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                  var notification = new Notification("Hi there!");
                }
              });
            }


          });

        }
        */