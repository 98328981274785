
//import { store } from '../../index';
//import * as dialogActions from '../../actions/dialog-actions';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '../../css/swal-material-ui.min.css';

export default class Utils {

    static Debug( msg )
    {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log( msg )
        }
    }

    static DevMode()
    {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return true;
        }
        return false;
    }

    // has no string or empty length string
    static HasString( src )
    {
        if ( ! src || typeof src !== "string" )
        {
            return false;
        }

        return src.length > 0;
    }

    static EnsureString( src )
    {
        if ( ! src || typeof src !== "string" )
        {
            return "";
        }
        return src;
    }

    static EndsWithNoCase( src, search )
    {
        if ( typeof src !== "string" || typeof search !== "string" )
        {
            return false;
        }

        return src.toLowerCase().endsWith( search.toLowerCase() );
    }

    static StartsWithNoCase( src, search )
    {
        if ( typeof src !== "string" || typeof search !== "string" )
        {
            return false;
        }

        return src.toLowerCase().startsWith( search.toLowerCase );
    }

    static NotImplemented()
    {
        Utils.Alert( "Not implemented yet" );
        // window.alert( "Not implemeted yet" );
        // store.dispatch( dialogActions.showAlert( "Not implemeted yet") );
    }

    static Prompt( msg, callback )
    {
        let answer = window.prompt(msg);
        if ( callback )
        {
            callback( answer );
        }
    }

    static FlagIsSet( flag, value ) {

        return ( (flag & value) == value );
    }

    static Alert( msg )
    {
        // timeout needed to get around clash with spectrum scroll bar handling
        setTimeout(() => {
            Swal.fire({
                // title: 'Error!',
                text: msg,
                //icon: 'error',
                //confirmButtonText: 'Cool'
                showClass: { popup: 'alert-show' },
                hideClass: { popup: 'alert-hide' },
                backdrop: "#00000038",
                scrollbarPadding: false,
                heightAuto: false,
                customClass: {
                    confirmButton: "alertConfirmButton",
                    popup: "alert-popup"
                },
                buttonsStyling: false,
            })
        }, 300);

    }

    CookiesEnabled()
    {
        try {
          document.cookie = 'cookietest=1';
          var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
          document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
          return cookiesEnabled;
        } catch (e) {
          return false;
        }
    }

    LocalStorageAvailable() {
        try {
            let storage = window["localStorage"],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    }
}