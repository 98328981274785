import { faExternalLinkAlt, faSyncAlt, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable from 'react-data-table-component';
import { subscribe } from "redux-subscriber";
import { ApplicationStatus } from '../classes/ApplicationsSummary';
import Broadcast from '../classes/Broadcast';
import Globals from '../classes/Globals';
import RecentSubmissions from '../classes/RecentSubmissions';
import { SubmissionInfo } from '../classes/RecentSubmissions';
import Urls from '../classes/Urls';
import User from '../classes/User';
import SearchBox from '../ui/SearchBox';
import Spacer from '../ui/Spacer';
import SubmissionStatusSlug from './SubmissionStatusSlug';
import Delete from './Icons/Delete'
import eventBus, { eventBusEvents } from '../classes/EventBus';
import { isMobileOnly } from 'react-device-detect';

export default class RecentSubmissionsDisplay extends React.Component {

    subscribeList = null;

    UNFILTERED = -1;
    allSubmissions = [];

    lastAddedSubmission = 0;

    constructor( props )
    {
        super(props);

        this.state = { loadingList: true, submissions: [], submissionFilter: this.UNFILTERED, searchTerm: "", deleteID: 0 };
    }

    componentDidMount() {

      let broadcast = Globals.Broadcast.Channel();
      broadcast.addEventListener('message', this.handleBroadcastMessage);

        this.subscribeList = subscribe('recent', state => {

                 // remove all submissions and reload
                // data table doesn't reload when status changes, only happens if id is changed??

                this.allSubmissions = state.recent.list;

                this.setState( {  submissions: [] }, () => {
                    this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, this.state.searchTerm ) }, () =>{
                        this.setState( { loadingList: false } );
                    } )
                  })
                 } )

        this.removeEventBus();
        eventBus.on( eventBusEvents.SUBMISSION_DELETED, this.submissionDeleted )

        this.loadRecent();

    }

    componentWillUnmount() {

      let broadcast = Globals.Broadcast.Channel();
      broadcast.removeEventListener('message', this.handleBroadcastMessage);
      this.removeEventBus()
        if ( this.subscribeList )
        {
            this.subscribeList();
            this.subscribeList = null;
        }
    }

    removeEventBus = () => {
      eventBus.remove( eventBusEvents.SUBMISSION_DELETED, this.submissionDeleted )
    }

    submissionDeleted = ( data ) => {
      if ( data && data.id > 0 )
      {
        let subs = [];
        for ( var i = 0 ; i < this.allSubmissions.length; i++ )
        {
            let sub = this.allSubmissions[i];
            if ( parseInt(sub.SubmissionID) !== parseInt(data.id) )
            {
              subs.push( sub );
              // remove all submissions and reload
              // data table doesn't reload when status changes, only happens if id is changed??
            }
        }
        this.allSubmissions = subs;
        this.setState( {  submissions: [] }, () => {
          this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, this.state.searchTerm ) } )
        })
      }
    }

  // received msg from another tab
  handleBroadcastMessage = ( msg ) => {

    if ( ! msg )
    {
      return;
    }

    switch( msg.type )
    {

      case Broadcast.SUBMISSION_STATUS_UPDATED:
      {

        let id = msg.id;
        let status = msg.status;
        if ( ! ApplicationStatus.IsValid( status ) || isNaN( id ) || parseInt(id) <= 0 )
        {
          return;
        }
/*
        if ( status === ApplicationStatus.SHORTLISTED )
        {
          // submission has been marked as shortlisted, add to to shortlisted list
           //  this.loadShortlistSubmission( id );
           // jsut reload list - TODO just load updated info
           this.loadRecent();
        }
        */
      }
        break;

      default:
          break;
    }
  }



    columns = [

        {
            name: <div className="submissions_table_header">Client</div>,
            selector: 'Client',
            sortable: true,
            grow: 1,
            xmaxWidth: "290px"
          },
          {
            name: <div className="submissions_table_header">Position</div>,
            selector: 'PositionTitle',
            sortable: true,
            grow: 1,
            xmaxWidth: "290px"
          },
          {
            name: <div className="submissions_table_header">Applicant</div>,
            selector: 'CandidateName',
            sortable: true,
            grow: 1,
            xmaxWidth: "290px"
          },
        {
          name: <div className="submissions_table_header">Status</div>,
          //name: <StatusPickerButton callback={this} />,
          selector: 'Status',
          cell: row => <SubmissionStatusSlug type={row.Status} count={-1} isClient={true} />,
          sortable: true,
          center: true,
          width: '105px',
        }, /*
        {
          name: <div className="submissions_table_header">Interview</div>,
          selector: 'interview_date',
          sortable: true,
          grow: 0,
          width: '100px',
          format: row => <InterviewDisplayDate date={row.interview_date} status={row.status} interview_status={row.interview_status} />,
          center: true
        }, */
        {
          name: <div className="submissions_table_header">Received</div>,
          selector: 'DateSubmitted',
          sortable: true,
          grow: 0,
          width: '90px',
          format: row => this.formatDate(row.DateSubmitted),
          center: true
        },
        {
          name: "",
          sortable: false,
          selector: (row) => { return ( <div onClick={() => this.showSubmission(row.SubmissionID)} title="View submission" className="submissions_display_link_icon" ><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon></div>  )},
          grow: 0,
          width: '35px',
          center: true
        },
        ,
        {
          name: "",
          sortable: false,
          selector: (row) => { return ( <div onClick={() => this.deleteSubmission(row.SubmissionID, row.CandidateName)} title="Delete submission" className="submissions_display_delete_icon" ><Delete /></div>  )},
          grow: 0,
          width: '35px',
          center: true
        }
      ];

      customStyles = {

          rows: {
            style: {
              minHeight: '36px', // override the row height
              fontSize: '15px'
            }
          },
          headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '15px',
            },
          },
          headRow:{
              style: {
                  minHeight: '18px',
                  background: '#e8e8e8',
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  fontSize: '15px'

              }
          },
          cells: {
            style: {
              paddingLeft: '8px', // override the cell padding for data cells
              paddingRight: '8px',
            },
          },
          pagination: {
              style: {
                  minHeight: '0'
              }
          }
        };

        conditionalRowStyles = [
          {
            when: row => row.New,
            style: {
              backgroundColor: '#f0e7ff',
              color: 'black',
            },
          }
        ];

      formatDate = ( date ) =>
      {
        let d = new Date( date * 1000 ).toLocaleDateString(undefined, {
          day : 'numeric',
          month : 'short'} );
        return d;
      }

      /*
      loadShortlistSubmission = async ( submission_id, options ) => {
        let bz = new BioZing();
        let response = await bz.LoadShortlistedSubmission(submission_id, options);
     //   console.log (response );
        if ( response && response.result === IOStatus.OK )
        {
          let short = ShortlistedSubmissions.CreateFromAPIReturn( response.application, response.submission );
      //    console.log( short );
        }
      }
      */

    loadRecent = async ( ) => {
        let sl = new RecentSubmissions();
        await sl.LoadFromServer( { limit: 30, days: 90 } );
        // Globals.FirebaseInstance.AddShortlistListeners();
    }

    filterSubmissions = ( filter, subs, search ) => {

      if ( ! subs )
      {
        return [];
      }

      let doSearch = search && search.length > 0;
      let searchTerm = "";
      if ( doSearch )
      {
        // TODO check against non latin characters
        searchTerm = search.toLowerCase();
      }


      if ( filter === this.UNFILTERED && ! doSearch )
      {
        return subs;
      }

      let filtered = [];
      for ( var i = 0; i < subs.length; i++ )
      {
        let sub = subs[i];
       // if ( sub.status === filter || filter === this.UNFILTERED || ( filter === ApplicationStatus.DECLINED && sub.status === ApplicationStatus.CLIENT_DECLINED ) )
       // {
          if ( doSearch )
          {
            if ( sub.CandidateName && sub.CandidateName.toLowerCase().indexOf( searchTerm ) !== -1 )
            {
              filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
            }
            else
            {
              if ( sub.Client && sub.Client.toLowerCase().indexOf( searchTerm ) !== -1 )
              {
                filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
              }
              else
              {
                if ( sub.PositionTitle && sub.PositionTitle.toLowerCase().indexOf( searchTerm ) !== -1 )
                {
                  filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
                }
              }
            }
          }
          else
          {
            filtered.push( sub );// Object.assign( {}, sub ) );
          }
       // }
      }
      return filtered;
    }

    reloadClicked = () => {

      this.setState( { loadingList: true } );
      this.loadRecent();
    }

    doSearch = ( txt ) => {

      this.setState( { searchTerm: txt, submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, txt ) } );
    }

    showSubmission = (id) => {
        if ( id && id > 0 )
        {
          let user = User.CurrentUser();

          window.open( new Urls().ViewSubmissionUrl() + id + "?token=" + user.UserToken, "_blank" );
        }
      }

      deleteSubmission = (id, applicant_name) => {
        if ( id && id > 0 )
        {
          eventBus.dispatch( eventBusEvents.DELETE_SUBMISSION, { "id": id, "applicant_name": applicant_name } )
        }
      }
    render() {

        let hasSubmissions = this.state.submissions && this.state.submissions.length > 0;
        let title = "Latest Applicant Submissions";
        if ( isMobileOnly )
        {
          title = "Latest Submissions"
        }

        return (

            <div className="applications_summary shortlist">
              <div className="shortlisted_header">
                <div className="shortlist_title">{title}<div onClick={this.reloadClicked} className="dashboard_sync_button"><FontAwesomeIcon icon={faSyncAlt}></FontAwesomeIcon></div></div>
                <div className="submissions_search"><SearchBox onSearch={this.doSearch} immediate={true} /></div>
                </div>
                <Spacer height={3} />
                {this.state.loadingList && <><Spacer height={10} /><div>Loading....</div></>}
                {(! this.state.loadingList && ! hasSubmissions) && <><Spacer height={10} /><div>No recent submissions found</div></>}
                {(! this.state.loadingList && hasSubmissions) && <>

                        <DataTable className="shortlist_table"
                            progressPending={this.state.loading}
                            title=""
                            noHeader={true}
                            pagination={true}
                            columns={this.columns}
                            customStyles={this.customStyles}
                            data={this.state.submissions}
                            //noDataComponent={noSubmisisons}
                            //progressComponent={progress}
                            defaultSortField="LastUpdate"
                            defaultSortAsc={false}
                            conditionalRowStyles={this.conditionalRowStyles}
                            />
                        </>
                }
            </div>
        )
    }
}