import React, { Suspense } from 'react';
import Notifications from 'react-notify-toast';
import * as userActions from '../actions/user-actions';
import '../css/App.css';
import { store } from '../index';
import Auth from './classes/Auth';
import AuthStatus from './classes/AuthStatus';
import Globals from './classes/Globals';
import IOStatus from './classes/IOStatus';
//import Firebase from './Firebase/Firebase';
import User from './classes/User';
import AppHeader from './components/AppHeader';
import HomePage from './components/HomePage';
import RecoverAccountForm from './components/RecoverAccountForm';
import SignedInPage from './components/SignedInPage';
import {isMobile, isMobileOnly} from 'react-device-detect'
import Utils from './classes/Utils';
import GenericBusy from './components/GenericBusy';
import GenericBusyOptions from './classes/GenericBusyOptions';
import AppMenu from './components/AppMenu';
// import ClientsPage from './components/ClientsPage';


const ClientsPage = React.lazy(() => import( './components/ClientsPage'))
const CandidatesPage = React.lazy(() => import( './components/CandidatesPage'))
const PositionsPage = React.lazy(() => import( './components/PositionsPage'))

const queryString = require('query-string');

export default class App extends React.Component
{

  passwordReset = "";
  busyOptions = new GenericBusyOptions( true );

  constructor ( props )
  {
    super( props );


    let showPasswordReset = false;
    let showCreateAccount = false;



    const parsed = queryString.parse(window.location.search);
    if ( parsed && parsed.pwr && parsed.pwr.length > 0 )
    {
      showPasswordReset = true;
      this.passwordReset = parsed.pwr;
    }

    if ( parsed && parsed.action && parsed.action.length > 0 )
    {
      if ( parsed.action === "signup" )
      {
        showCreateAccount = true;
      }
    }

    let startPage = Globals.LoadStartPage();
    let clientsPageVisible = !!(startPage == "clients")
    let candidatesPageVisible = !!(startPage == "candidates")
    let positionsPageVisible = !!(startPage == "positions")
    if ( isMobileOnly )
    {
      // not ready for phone
      clientsPageVisible = false;
      candidatesPageVisible = false;
      positionsPageVisible = false;
    }

    this.state = { authStatus: AuthStatus.CHECKING, applicationsList: [], showPasswordReset: showPasswordReset,
                  showCreateAccount: showCreateAccount, clientsPageVisible: clientsPageVisible, candidatesPageVisible: candidatesPageVisible,
                  positionsPageVisible: positionsPageVisible }



  }



  componentDidMount() {

    if ( isMobileOnly )
    {
      let root = document.getElementById('root');
      if ( root )
      {
        root.classList.add( "mobileOnly" )
      }
    }

    if( ! this.state.showPasswordReset )
    {
      this.checkForSavedLogin();
    }

    if ( this.state.showCreateAccount )
    {
      try {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.replaceState({}, '', url );
      } catch( e )
      {

      }
    }
}


   checkForSavedLogin = async () => {

      let token = User.LoadSavedToken();
      if ( token && token.length > 0 )
      {
        // has token, try to log in with it
        let [status, result] = await Auth.LoginWithToken( token )
        switch ( status )
        {
            case IOStatus.OK:

                this.UserLoggedIn( result, true );
                return;

            default: break;
        }
      }
      this.checkAuth();
   }

   UserLoggedIn = ( data, keepLoggedIn ) => {

    if( this.state.showPasswordReset )
    {
      return;
    }

    let newUser = new User();

    newUser.Name = data.name;
    newUser.IsAnonymous = false
    newUser.Identifier = data.identifier;
    newUser.Email = data.email;
    newUser.EmailVerified = (data.verified === true);
    newUser.UserToken = data.token;

    User.SaveToken( data.token, keepLoggedIn );
    newUser.FirebaseToken = data.firebaseToken;
    newUser.FirebaseUid = data.identifier;

    newUser.DesktopNotifications = data.desktop_notifications;
    newUser.MobileNotifications = data.mobile_notifications;

    newUser.DesktopDeviceToken = Utils.EnsureString( data.desktop_deviceToken );
    newUser.MobileDeviceToken = Utils.EnsureString( data.mobile_deviceToken );


    store.dispatch( userActions.replaceUser( newUser) );

    if ( newUser.FirebaseToken && newUser.FirebaseToken.length > 0 )
    {
      Globals.FirebaseInstance.LogInWithCustomToken(newUser.FirebaseToken);
    }

    Globals.FirebaseInstance.StartPushNotifications( isMobile ? newUser.MobileNotifications : newUser.DesktopNotifications);

    this.AuthStatusChanged();
  }


   checkAuth = async () => {

    if( this.state.showPasswordReset )
    {
      return;
    }

    let user = User.CurrentUser();
    if  (user && user.LoggedIn() )
    {
      this.setState( { authStatus: AuthStatus.SIGNED_IN } )

      return;
    }

    this.setState( { authStatus: AuthStatus.NOT_SIGNED_IN } )

  }


  AuthStatusChanged = () => {
    this.checkAuth();
  }


  SignOut = async () => {

    Globals.FirebaseInstance.StopPushNotifications();

    await Auth.SignOut();

    this.AuthStatusChanged();

  }

  ShowClientsPage = () => {
    this.setState( { clientsPageVisible: true, candidatesPageVisible: false, positionsPageVisible: false } )
    Globals.SaveStartPage( "clients" )
  }

  ShowCandidatesPage = () => {
    this.setState( { candidatesPageVisible: true, clientsPageVisible: false, positionsPageVisible: false } )
    Globals.SaveStartPage( "candidates" )
  }

  ShowPositionsPage = () => {
    this.setState( { candidatesPageVisible: false, clientsPageVisible: false, positionsPageVisible: true } )
    Globals.SaveStartPage( "positions" )
  }


  ShowDashboard = () => {
    this.setState( { clientsPageVisible: false, candidatesPageVisible: false, positionsPageVisible: false  } )
    Globals.SaveStartPage( "dashboard" )
  }


  render() {


      // console.log( this.state.applicationsList );

      if( this.state.showPasswordReset )
      {
        return (
         <div className="login_content">
            <div className="login_logo"></div>
            <RecoverAccountForm pwr={this.passwordReset} />
            </div>
        )
      }

      let pageVisible = "dashboard";
      if ( this.state.clientsPageVisible )
      {
        pageVisible = "clients";
      }
      if ( this.state.candidatesPageVisible )
      {
        pageVisible = "candidates"
      }

      if ( this.state.positionsPageVisible )
      {
        pageVisible = "positions"
      }

          return (
            <div className="app">
              <AppHeader title="Recruiter Dashboard" callback={this} authStatus={this.state.authStatus} clientsPageVisible={this.state.clientsPageVisible}  candidatesPageVisible={this.state.candidatesPageVisible}></AppHeader>
              {this.state.authStatus === AuthStatus.SIGNED_IN &&
                <>
                  <div className="app-container">
                    {! isMobileOnly && <div className="app-menu"><AppMenu location={pageVisible} callback={this} /></div>}
                    {this.state.clientsPageVisible && <Suspense fallback={<GenericBusy options={this.busyOptions} />}><ClientsPage /></Suspense>}
                    {this.state.candidatesPageVisible && <Suspense fallback={<GenericBusy options={this.busyOptions} />}><CandidatesPage /></Suspense>}
                    {this.state.positionsPageVisible && <Suspense fallback={<GenericBusy options={this.busyOptions} />}><PositionsPage /></Suspense>}
                    {(! this.state.clientsPageVisible && ! this.state.candidatesPageVisible && ! this.state.positionsPageVisible) && <SignedInPage  callback={this} />}
                  </div>
                </>
              }
              {this.state.authStatus === AuthStatus.NOT_SIGNED_IN && <HomePage callback={this} showCreateAccount={this.state.showCreateAccount} /> }
              {this.state.authStatus === AuthStatus.CHECKING && <div className="login_content"><div className="content_loading_msg">Loading...</div></div>}
              <Notifications />
            </div>
          );
}
}
