import React from 'react';

class SearchBox extends React.Component {

  searchInput = null;

  constructor(props) {
    super(props);
    this.state = {value: this.props.defaultValue || "" };

    this.searchInput = React.createRef();
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
  }

  keyUp = (event) => {

    if ( event.keyCode === 13 )
    {
        this.doSearch(false);
        event.preventDefault( false );
    }
    else
    {
      // if immediate is true, sends search back after keypress
      if ( this.props.immediate && this.props.onSearch )
      {
          this.props.onSearch(event.target.value, true );
      }
    }
  }

  doSearch = ( wasImmediate) => {
      if ( this.props.onSearch )
      {
          this.props.onSearch(this.state.value, wasImmediate );
      }
  }

  clearSearch = () => {
      this.setState( {value: ""} );
      this.searchInput.current.focus();
      if ( this.props.immediate && this.props.onSearch )
      {
          this.props.onSearch("", false);
      }
  }
/*
  componentDidUpdate( )
  {
    if ( this.state.value !== this.props.defaultValue )
    {
      this.setState( { value: this.props.defaultValue })
    }
  }
  */
  /*
  componentWillReceiveProps( nextProps )
  {
      this.setState( nextProps );
  }
  */
  render() {

      return (

          <div className="SearchBox">
            <div className="searchButton" onClick={() => this.doSearch(false)}></div>
            <input ref={this.searchInput} type="text" value={this.state.value} onKeyUp={this.keyUp} onChange={this.handleChange} placeholder="Search..." />
            {(this.state.value.length > 0 || this.props.alwaysShowClearButton) && <div className="searchClearButton" onClick={this.clearSearch}></div>}
          </div>
      );

  }
}

export default SearchBox;