import React, { useEffect, useState } from "react";
import { subscribe } from "redux-subscriber";
import DashboardNotificationDisplay from "./DashboardNotificationDisplay";
import { faExternalLinkAlt, faSyncAlt, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Delete from "./Icons/Delete";
import Cog from "./Icons/Cog"
import User from "../classes/User";
import Urls from "../classes/Urls";
import Globals from "../classes/Globals";
import * as notificationsActions from '../../actions/notifications-actions'
import { store } from "../..";
import superagent from 'superagent'
import { isDesktop, isMobile } from "react-device-detect";
import { DashboardNotificationType } from "../classes/DashboardNotification";
import { NotificationsType } from "../classes/NotificationTypes";


export default function DashboardNotificationsDisplay( props ) {

    let [list, setList] = useState( [] );
    let [filteredList, setFilteredList] = useState( [] );
    let [flags, setFlags] = useState(  isDesktop ? User.CurrentUser().DesktopNotifications : User.CurrentUser().MobileNotifications )

    useEffect( () => {

        let currentState = store.getState();
        if ( currentState && currentState.notifications && currentState.notifications.list )
        {
            setList( currentState.notifications.list )
        }
        if ( isMobile )
        {
            setFlags( currentState.user.user.MobileNotifications )
        }
        else
        {
            setFlags( currentState.user.user.DesktopNotifications)
        }

        const subscribeList = subscribe('notifications', state => {
            setList( state.notifications.list )
        });

        const subscribeUser = subscribe('user', state => {
            // setUser( state.user.user )
            if ( isMobile )
            {
                setFlags( state.user.user.MobileNotifications )
            }
            else
            {
                setFlags( state.user.user.DesktopNotifications)
            }
        });

        return ( () => {
            subscribeList();
            subscribeUser();
        } )

    }, [] )

    useEffect( () => {
        let newList = [];

        if ( NotificationsType.AllDashboard( flags ) )
        {
            setFilteredList( list )
            return;
        }
        list.forEach(note => {
            if ( note.Type == DashboardNotificationType.CLIENT )
            {
                if ( flagSet( flags, NotificationsType.DASHBOARD_ALL_CLIENT ) )
                {
                    newList.push( note );
                }
            }

            if ( note.Type == DashboardNotificationType.CANDIDATE )
            {
                if ( flagSet( flags, NotificationsType.DASHBOARD_ALL_CANDIDATE ) )
                {
                    newList.push( note );
                }
            }

        });

        setFilteredList( newList );

    },[list, flags])

    const flagSet = ( flag, val ) =>
    {
        return ((flag & val) == val);
    }

    const onShowNotificationOptions = () => {
        if ( props && props.callback && props.callback.showNotificationsOptionsDialog )
        {
            props.callback.showNotificationsOptionsDialog();
        }
    }

    const showSubmission = (id) => {
        if ( id && id > 0 )
        {
            let user = User.CurrentUser();

            window.open( new Urls().ViewSubmissionUrl() + id + "?token=" + user.UserToken, "_blank" );
        }
      }

    const deleteSubmission = ( note ) => {
        let firebase = Globals.FirebaseInstance;
        firebase.deleteSubmissionNotifications(note.SubmissionID)
        store.dispatch( notificationsActions.deleteNotification( note ) );
    }

    const notificationCallback = {
        notificationClicked: ( note ) => {
            showSubmission( note.SubmissionID )

        },
        deleteNotificationClicked: ( note ) => {
            deleteSubmission( note );
        },
    }

    const clearAllNotifications = () => {

        let url = new Urls().ClearSubmissionNotificationsUrl();
        let user = User.CurrentUser();

        store.dispatch( notificationsActions.replaceNotifications( [] ) );
        superagent.delete( url )
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('x-requested-with', 'XMLHttpRequest')
        .auth(user.UserToken, { type: 'bearer' })
        .end((error, response) => {
            console.log( error, response );
        } )
    }

    let showHolder = NotificationsType.ContainsDashboard( flags ); // flagSet( flags, DashboardNotificationType.CLIENT ) || flagSet( flags, DashboardNotificationType.CANDIDATE );

    return (
        <div className="dashboard_notifications_display">
            <div className="dashboard_notifications_header">
                <div className="dashboard_notifications_title">Notifications</div><div className="dashboard_notification_options_button" onClick={onShowNotificationOptions}><Cog /></div>
            </div>
            {showHolder &&
            <div className="dashboard_notifications_holder">
                {filteredList.map( function( value, index ) {
                    return <DashboardNotificationDisplay key={index} notification={value} callback={notificationCallback} />
                })}
                {filteredList.length > 0 && <div className="dashboard_notifications_clear_all" onClick={clearAllNotifications}> <div className="dashboard_notifications_clear_button"><Delete />Clear all</div></div>}
                {filteredList.length == 0 && <div className="dashboard_notifications_empty">No notifications</div>}
            </div>
            }
        </div>
    )
}