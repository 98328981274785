// user
export const USER_REPLACE = 'USER_REPLACE';
export const USER_UPDATE = "USER_UPDATE";


// applications
export const APPLICATION_ADD_CLIENT = "APPLICATION_ADD_CLIENT";


// shortlisted submissions
export const SHORTLISTED_REPLACE_ALL = "SHORTLISTED_REPLACE_ALL";
export const SHORTLISTED_UPDATE_STATUS = "SHORTLISTED_UPDATE_STATUS";
export const SHORTLISTED_ADDED_NEW_SUBMISSION = "SHORTLISTED_ADDED_NEW_SUBMISSION";


// dashboard notifications
export const NOTIFICATIONS_ADD = "NOTIFICATIONS_ADD";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const REPLACE_NOTIFICATIONS = "REPLACE_NOTIFICATIONS";


// recent submissions
export const RECENT_REPLACE_ALL = "RECENT_REPLACE_ALL";
export const RECENT_UPDATE_STATUS = "RECENT_UPDATE_STATUS";
export const RECENT_ADDED_NEW_SUBMISSION = "RECENT_ADDED_NEW_SUBMISSION";
