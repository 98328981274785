import * as types from '../actions/action-types';

const initialState = {
    list: [],
    new: 0
}

export default (state = initialState, action) => {

  switch (action.type) {


      case types.RECENT_REPLACE_ALL:


          return Object.assign({}, state, { list: action.recent } );

      case types.RECENT_UPDATE_STATUS:

        //  console.log( "action: ", action );
          let updatedList = [];
          let id = parseInt( action.data.submission_id )
          for ( let i = 0; i < state.list.length; i++ )
          {
            let sub = state.list[i];
            if ( sub.SubmissionID == id )
            {
              sub.New = sub.Status !== action.data.status;
              sub.Status = action.data.status;
              sub.LastUpdate = action.data.date;
            }
            updatedList.push( sub );
          }
          return Object.assign({}, state, { list: updatedList } );

      case types.RECENT_ADDED_NEW_SUBMISSION:

          // console.log( "action: ", action );
          return Object.assign({}, state, { new: action.submission_id } );


    default:
      return state;
  }

};