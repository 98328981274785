import Utils from "./Utils";

export class NotificationsType {

    // use as flags to store as single number
    static NONE = 0;
    static DASHBOARD_ALL_CLIENT = 1;  // all client related
    static DASHBOARD_ALL_CANDIDATE = 2; // all candidate related

    static PUSH_ALL_CLIENT = 4;  // all client related
    static PUSH_ALL_CANDIDATE = 8; // all candidate related

    // may add specific ones later


    static AllDashboard = ( flags ) => {

        if ( Utils.FlagIsSet( flags, NotificationsType.DASHBOARD_ALL_CLIENT ) && Utils.FlagIsSet( flags, NotificationsType.DASHBOARD_ALL_CANDIDATE ) )
        {
            return true;
        }

        return false;
    }

    static ContainsPush = ( flags ) => {

        if ( Utils.FlagIsSet( flags, NotificationsType.PUSH_ALL_CANDIDATE ) || Utils.FlagIsSet( flags, NotificationsType.PUSH_ALL_CLIENT) )
        {
            return true;
        }

        return false;
    }

    static ContainsDashboard = ( flags ) => {
        if ( Utils.FlagIsSet( flags, NotificationsType.DASHBOARD_ALL_CANDIDATE ) || Utils.FlagIsSet( flags, NotificationsType.DASHBOARD_ALL_CLIENT) )
        {
            return true;
        }

        return false;
    }
}
