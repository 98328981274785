export const eventBusEvents = {

    AUTH_STATUS_CHANGED: "AUTH_STATUS_CHANGED",
    DELETE_SUBMISSION: "DELETE_SUBMISSION",
    ARCHIVE_SUBMISSION: "ARCHIVE_SUBMISSION",
    SUBMISSION_DELETED: "SUBMISSION_DELETED",
    GENERIC_BUSY: "GENERIC_BUSY"

}

const eventBus = {

    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export default eventBus;