import React from "react";
import DashboardNotification, { DashboardNotificationType } from "../classes/DashboardNotification";
import ApplicationsSummary, { ApplicationStatus } from "../classes/ApplicationsSummary";
import { faExternalLinkAlt, faSyncAlt, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Delete from "./Icons/Delete";

export default function DashboardNotificationDisplay( props ) {

    let note = props.notification;

    let cls = "dashboard_notification_display";
    if (props.notification.Type == "client" )
    {
        cls += " " + "type_client";
    }

    if (props.notification.Type == "candidate" )
    {
        cls += " " + "type_candidate";
    }
    let status = "";
    let candidateName = note.CandidateName;


    let statusCls = "dashboard_notification_display_status";

    switch( note.Type )
    {


        case DashboardNotificationType.CLIENT:
        {
            if ( note.Status == ApplicationStatus.CLIENT_ACCEPTED )
            {
                status = "Client Accepted";
                statusCls += " " + "accepted";
            }

            if ( note.Status == ApplicationStatus.CLIENT_DECLINED )
            {
                status = "Client Declined";
                statusCls += " " + "declined";
            }
            break;
        }

        case DashboardNotificationType.CANDIDATE:
        {
            status = "New application";
            break;
        }

        default: return null;


    }



    const clicked = (e) => {
        e.stopPropagation()
        e.preventDefault();

        props.callback.notificationClicked( props.notification )
    }

    const trash = (e) => {
        e.stopPropagation()
        e.preventDefault();
        props.callback.deleteNotificationClicked( props.notification )
    }


    return  <div className={cls} onClick={clicked}>
                <div className="dashboard_notification_display_title">{props.notification.ApplicationTitle}</div>
                <div className="dashboard_notification_display_client">{note.ClientName}</div>
                <div className={statusCls}>{status}</div>
                <div className="dashboard_notification_display_candidate">{candidateName}</div>
                <div className="dashboard_notification_display_date">{note.DateStr}</div>
                <div className="dashboard_notification_display_trash" onClick={trash} title="Delete notification"><Delete /></div>
            </div>
}