import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import eventBus, { eventBusEvents } from '../classes/EventBus';
import Globals from '../classes/Globals';
import Utils from '../classes/Utils';
import ApplictionsSummaryDisplay from '../components/ApplicationsSummaryDisplay';
import Spacer from '../ui/Spacer';
import ApplicationsList from './ApplicationsList';
import DashboardNotificationsDisplay from './DashboardNotificationsDisplay';
import DeleteSubmissionDialog from './DeleteSubmissionDialog';
import LatestSubmissions from './LatestSubmissions';
import NotificationsOptionsDialog from './NotificationsOptionsDialog';
import ShortlistedSubmissionsDisplay from './ShortlistedSubmissionsDisplay';
import RecentSubmissionsDisplay from './RecentSubmissionsDisplay'





export default class Dashboard extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = { deleteID: 0, deleteApplicantName: "", notificationOptionsVisible: false}
    }

    componentDidMount() {

        if ( Utils.DevMode() )
        {
            if ( Globals.FirebaseApp )
            {
                // Globals.FirebaseInstance.InitPushMessaging();
            }
        }

        this.removeEventBus()
        eventBus.on( eventBusEvents.DELETE_SUBMISSION, this.deleteSubmission )
    }

    componentWillUnmount() {
        this.removeEventBus();
    }



    removeEventBus = () => {
        eventBus.remove( eventBusEvents.DELETE_SUBMISSION, this.deleteSubmission )
    }

    deleteSubmission = ( data ) => {
        if ( data && data.id )
        {
            let applicant_name = Utils.EnsureString( data.applicant_name );
            this.setState( { deleteID: data.id, deleteApplicantName: applicant_name } )
        }
    }

    reloadPage = () => {
        window.location.reload();
    }

    closeDeleteSubmissionDialog = () => {
        this.setState( { deleteID: 0} )
    }

    createApplication = () => {

            let url = '../application';
            if ( Utils.DevMode() )
            {
                window.open( "http://localhost:3001", "_blank" );
            }
            else
            {
                window.open( url, "_blank" );
            }
        }

        showNotificationsOptionsDialog = () => {
            this.setState( { notificationOptionsVisible: true } )
        }

        closeNotificationOptionsDialog = () => {
            this.setState( { notificationOptionsVisible: false } )
        }

    render() {

        return (

            <>
            <div className="dashboard">
                <div className="dashboard_top_buttons">
                    <div className="dashboard_title">Dashboard</div>
                    {! isMobileOnly && <div className="action_button dashboard_button" onClick={this.createApplication}>Create a Position</div>}
                </div>
                <DashboardNotificationsDisplay callback={this} />
                <Spacer height={20} />
                <ShortlistedSubmissionsDisplay />
                <Spacer height={20} />
                <RecentSubmissionsDisplay />
                {false && <ApplictionsSummaryDisplay />}
                <Spacer height={20} />
                <div className="pwa_reload_buttons">
                    <div className="pwa_reload_button" onClick={this.reloadPage}><FontAwesomeIcon icon={faSyncAlt}></FontAwesomeIcon> Reload</div>
                </div>
            </div>
            {this.state.deleteID > 0 && <DeleteSubmissionDialog showArchive={true} id={this.state.deleteID}  applicant_name={this.state.deleteApplicantName} onClose={this.closeDeleteSubmissionDialog} />}
            {this.state.notificationOptionsVisible && <NotificationsOptionsDialog callback={this} />}
            </>
        )
    }
}