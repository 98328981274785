import * as types from './action-types';

// DashboardNotification
export const addNotification = ( notification ) => {
  return {
    type: types.NOTIFICATIONS_ADD,
    notification
  };

}


export const deleteNotification = ( notification ) => {

  return {
    type: types.DELETE_NOTIFICATION,
    notification
  };
}

// array of DashboardNotifications
export const replaceNotifications = ( notifications ) => {

  return {
    type: types.REPLACE_NOTIFICATIONS,
    notifications
  };
}
