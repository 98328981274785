import { act } from 'react-dom/test-utils';
import * as types from '../actions/action-types';

const initialState = {
    list: []
}

export default (state = initialState, action) => {

  switch (action.type) {

      case types.REPLACE_NOTIFICATIONS:

        return Object.assign({}, state, { list: action.notifications } );


      case types.DELETE_NOTIFICATION:

        let note = action.notification;
        if ( ! note ) return state;

        let newList = [];
        for ( let i = 0; i < state.list.length; i++ )
        {
          if ( ! ( note.SubmissionID == state.list[i].SubmissionID && note.Type == state.list[i].Type ) )
          {
            newList.push(state.list[i])
          }
        }
        return Object.assign({}, state, { list: newList } );

      case types.NOTIFICATIONS_ADD:
        {
        let newList = [action.notification];
        // check for duplicates
          for ( let i = 0; i < state.list.length; i++ )
          {
            if ( ! ( action.notification.SubmissionID == state.list[i].SubmissionID && action.notification.Type == state.list[i].Type ) )
            {
              newList.push( state.list[i])
            }
          }
          // return Object.assign({}, state, { list: [action.notification, ...state.list] } );
          return Object.assign({}, state, { list: newList } );
        }
          /*
      case types.SHORTLISTED_UPDATE_STATUS:

        //  console.log( "action: ", action );
          let updatedList = [];
          let id = parseInt( action.data.submission_id )
          for ( let i = 0; i < state.list.length; i++ )
          {
            let sub = state.list[i];
            if ( sub.SubmissionID == id )
            {
              sub.New = sub.Status !== action.data.status;
              sub.Status = action.data.status;
              sub.LastUpdate = action.data.date;
            }
            updatedList.push( sub );
          }
          return Object.assign({}, state, { list: updatedList } );

      case types.SHORTLISTED_ADDED_NEW_SUBMISSION:

          // console.log( "action: ", action );
          return Object.assign({}, state, { new: action.submission_id } );
          */

    default:
      return state;
  }

};