import React from 'react';
import '../../css/menu.css';






export default function AppMenu(props) {

    const selectedMenu = (menuname) => {
        return props.location === menuname ? 'selected' : ''

    };

    const changeLocation = ( loc ) => {
        switch (loc)
        {
            case "clients":

                    if ( props.callback && props.callback.ShowClientsPage )
                    {
                        props.callback.ShowClientsPage();
                    }
                break;

            case "dashboard":

                if ( props.callback && props.callback.ShowDashboard )
                {
                    props.callback.ShowDashboard();
                }
                break;

            case "candidates":

                if ( props.callback && props.callback.ShowCandidatesPage )
                {
                    props.callback.ShowCandidatesPage();
                }
                break;

            case "positions":

                if ( props.callback && props.callback.ShowPositionsPage )
                {
                    props.callback.ShowPositionsPage();
                }
                break;

            default:
                break;
        }
     }

    return (


        <div className='menu'>
            <ul>
                <li className={selectedMenu('dashboard')} onClick={() => changeLocation("dashboard")}>
                        <i className='icn'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" fill="black" />
                            </svg>
                        </i>
                        <div>Dashboard</div>
                </li>
                <li className={selectedMenu('candidates')}  onClick={() => changeLocation("candidates")}>
                        <i className='icn'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 10.56C13.4787 10.56 14.6775 9.37631 14.6775 7.91621C14.6775 6.45611 13.4787 5.27246 12 5.27246C10.5213 5.27246 9.32251 6.45611 9.32251 7.91621C9.32251 9.37631 10.5213 10.56 12 10.56Z" fill="black" />
                            <path d="M7.93876 8.18057H8.20876V7.9387C8.21039 7.23535 8.40882 6.54649 8.7816 5.95005C9.15438 5.35361 9.68662 4.87339 10.3181 4.5637C10.1312 4.13314 9.83109 3.76126 9.44976 3.48755C9.06843 3.21384 8.62008 3.04852 8.15234 3.00916C7.68459 2.96979 7.21492 3.05785 6.79321 3.26397C6.37149 3.4701 6.01348 3.7866 5.75719 4.17986C5.5009 4.57311 5.35592 5.02844 5.33762 5.49748C5.31933 5.96652 5.42842 6.43176 5.6533 6.84378C5.87819 7.2558 6.21046 7.59922 6.61485 7.83756C7.01923 8.0759 7.48062 8.20027 7.95001 8.19745L7.93876 8.18057ZM15.8025 7.9162V8.15807H16.0725C16.5356 8.15342 16.989 8.02401 17.3847 7.78344C17.7805 7.54288 18.1041 7.20007 18.3215 6.79109C18.5389 6.38211 18.642 5.92209 18.62 5.45946C18.598 4.99682 18.4516 4.54868 18.1964 4.1622C17.9411 3.77572 17.5864 3.46519 17.1696 3.26331C16.7527 3.06144 16.2892 2.97566 15.8277 3.01503C15.3662 3.0544 14.9238 3.21745 14.5472 3.48701C14.1706 3.75658 13.8736 4.1227 13.6875 4.54683C14.3192 4.8553 14.852 5.33431 15.2258 5.92972C15.5995 6.52513 15.7993 7.21321 15.8025 7.9162ZM14.4131 10.8243C15.5315 11.0457 16.6129 11.4245 17.625 11.9493C17.7676 12.0276 17.8967 12.1283 18.0075 12.2474H21V10.3237C21.0005 10.2499 20.9809 10.1774 20.9432 10.1139C20.9056 10.0505 20.8513 9.99854 20.7862 9.9637C19.3283 9.20113 17.7065 8.80533 16.0612 8.81057H15.69C15.4951 9.60297 15.0467 10.3101 14.4131 10.8243ZM5.54813 13.3387C5.54711 13.0554 5.62361 12.7772 5.76936 12.5343C5.91512 12.2914 6.12456 12.093 6.37501 11.9606C7.3871 11.4357 8.46848 11.0569 9.58688 10.8356C8.95636 10.3257 8.5083 9.62497 8.31 8.8387H7.93876C6.29346 8.83346 4.67167 9.22925 3.21376 9.99182C3.14872 10.0267 3.09443 10.0786 3.05676 10.1421C3.01909 10.2055 2.99947 10.278 3.00001 10.3518V13.9462H5.54813V13.3387ZM13.9462 16.5843H17.2987V17.3718H13.9462V16.5843Z" fill="black" />
                            <path d="M20.3306 13.5299H16.4662V12.9674C16.4662 12.8183 16.407 12.6752 16.3015 12.5697C16.196 12.4642 16.0529 12.4049 15.9037 12.4049C15.7545 12.4049 15.6115 12.4642 15.506 12.5697C15.4005 12.6752 15.3412 12.8183 15.3412 12.9674V13.5299H14.25V11.9381C13.5099 11.7827 12.7562 11.7016 12 11.6962C10.2246 11.6888 8.47458 12.1176 6.90373 12.9449C6.83376 12.9812 6.77519 13.0362 6.73449 13.1037C6.69379 13.1712 6.67255 13.2486 6.67311 13.3274V16.4831H10.6725V19.9087C10.6725 20.0579 10.7317 20.2009 10.8372 20.3064C10.9427 20.4119 11.0858 20.4712 11.235 20.4712H20.3306C20.4798 20.4712 20.6228 20.4119 20.7283 20.3064C20.8338 20.2009 20.8931 20.0579 20.8931 19.9087V14.0924C20.8931 13.9433 20.8338 13.8002 20.7283 13.6947C20.6228 13.5892 20.4798 13.5299 20.3306 13.5299ZM19.7681 19.3574H11.7975V14.6549H15.3412V15.1668C15.3412 15.316 15.4005 15.4591 15.506 15.5646C15.6115 15.6701 15.7545 15.7293 15.9037 15.7293C16.0529 15.7293 16.196 15.6701 16.3015 15.5646C16.407 15.4591 16.4662 15.316 16.4662 15.1668V14.6549H19.7681V19.3574Z" fill="black" />
                        </svg>
                    </i>
                        <div>Candidates</div>
                </li>
                <li className={selectedMenu('clients')}  onClick={() => changeLocation("clients")}>

                    <i className='icn'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3636 6.16589C18.4402 5.08927 17.9493 3.83179 17.3636 3.22028L14.7796 0.63641C13.6943 -0.440202 12.4453 0.0507331 11.8338 0.63641L10.3695 2.10921H8.13867C6.50213 2.10921 5.55466 2.9705 5.07232 3.96099L1.24799 7.78511V11.2303L0.636443 11.8332C-0.440225 12.9184 0.0507357 14.1673 0.636443 14.7788L3.22045 17.3626C3.68557 17.8277 4.18514 18 4.65887 18C5.27042 18 5.83029 17.6985 6.16621 17.3626L8.49181 15.0286H11.584C13.0483 15.0286 13.789 14.1156 14.056 13.2198C15.0293 12.9615 15.5634 12.2207 15.7787 11.4973C17.1138 11.1528 17.6133 9.88666 17.6133 8.99953V6.41566H17.1052L17.3636 6.16589ZM15.8907 8.99953C15.8907 9.38711 15.727 9.86082 15.0293 9.86082H14.168V10.7221C14.168 11.1097 14.0044 11.5834 13.3067 11.5834H12.4453V12.4447C12.4453 12.8323 12.2817 13.306 11.584 13.306H7.78552L4.96034 16.131C4.69333 16.3808 4.53829 16.2344 4.44354 16.1396L1.86815 13.573C1.61836 13.306 1.76479 13.1509 1.85954 13.0562L2.97066 11.9365V8.49137L4.69333 6.76879V8.13824C4.69333 9.1804 5.3824 10.7221 7.27733 10.7221C9.17227 10.7221 9.86134 9.1804 9.86134 8.13824H15.8907V8.99953ZM16.1405 4.94286L14.6762 6.41566H8.13867V8.13824C8.13867 8.52582 7.97501 8.99953 7.27733 8.99953C6.57965 8.99953 6.416 8.52582 6.416 8.13824V5.55437C6.416 5.15818 6.56242 3.83179 8.13867 3.83179H11.0758L13.0397 1.86805C13.3067 1.61828 13.4617 1.7647 13.5565 1.85944L16.1319 4.42608C16.3816 4.69308 16.2352 4.84811 16.1405 4.94286Z" fill="black" />
                        </svg>
                    </i><div>Clients</div>

                </li>

                <li className={selectedMenu('positions')}   onClick={() => changeLocation("positions")}>

                    <i className='icn positions'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 12.8V6.9992C19.0001 6.93605 18.9865 6.87351 18.9601 6.81517C18.9336 6.75683 18.8949 6.70382 18.846 6.6592L16.0915 4.1408C15.9931 4.05076 15.8597 4.00011 15.7205 4H5.525C5.38576 4 5.25223 4.05057 5.15377 4.14059C5.05531 4.23061 5 4.3527 5 4.48V19.52C5 19.6473 5.05531 19.7694 5.15377 19.8594C5.25223 19.9494 5.38576 20 5.525 20H13.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14 4V6.72C14 6.8473 14.0527 6.96939 14.1464 7.05941C14.2402 7.14943 14.3674 7.2 14.5 7.2H17.3333M14 17.6H19M19 17.6L16.5 15.2M19 17.6L16.5 20" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </i>
                        <span>Positions</span>
                </li>
                {false && <>
                <li className={selectedMenu('/notifications')}>

                    <i className='icn'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 0C4.93323 0 1.63637 3.02208 1.63637 6.75V10.3521L0.0584259 13.9717C-0.0423086 14.2028 -0.0114633 14.4646 0.140751 14.6706C0.292966 14.8766 0.546741 15 0.818181 15H5.72727C5.72727 16.6625 7.18634 18 9 18C10.8137 18 12.2727 16.6625 12.2727 15H17.1818C17.4533 15 17.707 14.8766 17.8593 14.6706C18.0115 14.4646 18.0423 14.2028 17.9416 13.9717L16.3636 10.3521V6.75C16.3636 3.02208 13.0669 0 9 0ZM10.6364 15C10.6364 15.8341 9.9099 16.5 9 16.5C8.0901 16.5 7.36364 15.8341 7.36364 15H10.6364ZM3.27273 6.75C3.27273 3.85051 5.83691 1.5 9 1.5C12.1631 1.5 14.7273 3.85051 14.7273 6.75V10.4964C14.7273 10.5917 14.7471 10.6862 14.7857 10.7747L15.9738 13.5H2.02624L3.21432 10.7747C3.25291 10.6862 3.27273 10.5917 3.27273 10.4964V6.75Z" fill="black" />
                        </svg>

                    </i>
                        <span>Notifications</span>

                </li>
                </>}
            </ul>
        </div>


    )
}
