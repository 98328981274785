import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import BioZing from '../classes/BioZing';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import IOStatus from '../classes/IOStatus';
import eventBus, { eventBusEvents } from '../classes/EventBus';
import Utils from '../classes/Utils';

export default function DeleteSubmisisonDialog( props ) {

    let [mounted, setMounted] = useState( false );
    let [isBusy, setIsBusy] = useState( false );
    let [errorMsg, setErrorMsg] = useState( "" );

    useEffect( () => {
        if( ! mounted )
        {
            setMounted( true );
        }
    }, [] )

    const archiveSubmission = async () => {
        let bz = new BioZing();
        setIsBusy( true );
        try {
        let res = await bz.ArchiveSubmission( props.id, true );
            if ( res == IOStatus.OK )
            {
                eventBus.dispatch( eventBusEvents.SUBMISSION_DELETED, { id: props.id } )
                close()
                return;
            }
        } catch( e )
        {
            console.log( e );
        }


        setErrorMsg( "Unable to archive submission" )
        setTimeout(() => {
            setIsBusy( false );
            setErrorMsg( "" )
        }, 2500);
    }


    const deleteSubmission = async () => {

        let bz = new BioZing();
        setIsBusy( true );
        try {
        let res = await bz.DeleteSubmission( props.id );
            if ( res == IOStatus.OK )
            {
                if ( props.onSubmissionDeleted )
                {
                    props.onSubmissionDeleted( { id: props.id } )
                    close()
                    return;
                }
                eventBus.dispatch( eventBusEvents.SUBMISSION_DELETED, { id: props.id } )
                close()
                return;
            }
        } catch( e )
        {
            console.log( e );
        }


        setErrorMsg( "Unable to delete submission" )
        setTimeout(() => {
            setIsBusy( false );
            setErrorMsg( "" )
        }, 2500);
    }

    const close = () => {
        if ( props.onClose )
        {
            props.onClose();
        }
    }

    let msg = props.showArchive ? "Delete or archive submission?" : "Delete submission?"
    if ( Utils.HasString( props.applicant_name ) )
    {
        msg = props.showArchive ? "Delete or archive submission by '" + props.applicant_name + "'?" : "Delete submission by '" + props.applicant_name + "'?"
    }

    return   (

        <CSSTransition
            in={mounted}
            timeout={300} unmountOnExit classNames="dialog-animate" >
            <div className='dialog'>
                <div className='dialog_mask'></div>
                    <div className='dialog_content'>
                        <div className='dialog_msg'>{msg}</div>
                        <div className='dialog_footer'>
                            <div className='dialog_footer_button action_button sm dialog_delete_button' onClick={deleteSubmission}>Delete</div>
                            {props.showArchive && <div className='dialog_footer_button action_button sm quiet' onClick={archiveSubmission}>Archive</div>}
                            <div className='dialog_footer_button action_button sm quiet dialog_cancel_button' onClick={close}>Cancel</div>
                        </div>
                        {isBusy &&
                            <div className='dialog_busy'>
                                {errorMsg.length > 0 && <div className='dialog_busy_error'>{errorMsg}</div>}
                                {errorMsg.length == 0 && <Loader type="Bars" color="#000000" height={24} width={24} />}
                                </div>
                            }
                    </div>
            </div>
        </CSSTransition>
    )
}