
import * as recentActions from '../../actions/recent-actions';
import { store } from '../../index';
import BioZing from './BioZing';
import IOStatus from './IOStatus';


// combined data from application and submission to display to recruiter
export class SubmissionInfo {

    SubmissionID = -1;
    Status = 0;
    CandidateName = "";
    CandidateEmail = "";
    CandidatePhone = "";
    ApplicationLink = "";
    LastUpdate = 0;
    Client = "";
    ClientID = 0;
    PositionID = "";
    ApplicationID = 0;
    PositionTitle = "";

    DateSubmitted = 0;
    // used to highlight in interface when a new message is received
    New = false;
}


export default class RecentSubmissions {

    // array of SubmissionInfo
    Submissions = [];

    submissionsUpdated = () => {

        store.dispatch( recentActions.replaceRecent( this.Submissions ) );
    }



    LoadFromServer = async ( options ) => {

        let bz = new BioZing();
        let response = await bz.LoadRecentSubmissions(options);

        if ( ! response || response.result !== IOStatus.OK )
        {
            this.submissionsUpdated();
            return;
        }
        if ( ! response.submissions || response.submissions.length === 0 )
        {
            this.submissionsUpdated();
            return;
        }

        for ( var i = 0; i < response.submissions.length; i++ )
        {

            let sub = response.submissions[i];

            let recent = new SubmissionInfo();

            recent.SubmissionID = sub.submission_id;
            recent.CandidateName = sub.applicant_name;
            recent.CandidateEmail = sub.applicant_email;
            recent.CandidatePhone = sub.applicant_phone;
            recent.ApplicationLink = sub.application_link;
            recent.DateSubmitted = sub.date_submitted;
            recent.Status = sub.status;
            recent.Client = sub.client;
            recent.ClientID = sub.client_id;
            recent.PositionID = sub.position_id;
            recent.PositionTitle = sub.title;

            this.Submissions.push( recent );

        }
        this.submissionsUpdated();
    }
/*
    // single retrieved submission
    static CreateFromAPIReturn = ( application, submission ) => {


        if ( ! application || ! submission )
        {
            return null;
        }

        let sub = submission;
        let app = application;

        let short = new ShortlistedSubmission();

        short.SubmissionID = sub.id;
        short.CandidateName = sub.applicant_name;
        short.CandidateEmail = sub.applicant_email;
        short.CandidatePhone = sub.applicant_phone;
        short.ApplicationLink = sub.application_link;
        short.LastUpdate = sub.last_update;
        short.Status = sub.status;

        short.Client = app.client;
        short.PositionID = app.position_id;
        short.PositionTitle = app.title;
        short.ApplicationID = app.id;

        return short;
    }
    */
}