export default class GenericBusyOptions {

    Show = false;
    Msg = "";

    constructor( show ) {
        if  ( show )
        {
            this.Show = show;
        }
    }
}