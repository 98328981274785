
import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notify } from 'react-notify-toast';
import BioZing from '../classes/BioZing';
import IOStatus from '../classes/IOStatus';
import Urls from '../classes/Urls';
import Utils from '../classes/Utils';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faThumbsUp, faCopy } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboardButton from '../ui/CopyToClipboardButton';
import Span from '../ui/Span';



export default class ApplicationDisplay extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = { isPosting: false, link: null }
    }

componentDidMount() {

}

    post = async () => {

        this.setState( { isPosting: true } );
        let  bz = new BioZing();
        let res = await bz.PostApplication( this.props.application.ID );
        // console.log( res )

        if ( res && res.result === IOStatus.OK && res.post_link && res.post_link.length > 0 )
        {
            //console.log( res.post_link );

            this.setState( { isPosting: false } );
            if ( this.props.callback && this.props.callback.ApplicationPosted )
            {
                this.props.callback.ApplicationPosted( this.props.application, res.post_link );
            }
            return;
        }
        Utils.Alert( "Could not post application" );
    }

    edit = () => {

        if ( this.props.application && this.props.application.ID > 0 )
        {
            let url = '../application' + "/" + this.props.application.ID;
            if ( Utils.DevMode() )
            {
            window.open( "http://localhost:3001" + "/" + this.props.application.ID, "_blank" );
            }
            else
            {
                window.open( url, "_blank" );
            }
        }
    }

    archive = async () => {
        console.log("aec" );
        notify.hide();
        notify.show( "Updating..." );
        let bz = new BioZing();

        await bz.Update( this.props.application.ID, { active: 0 } );

        this.madeActive( false );
        notify.hide();
    }

    unarchive = async () => {

        console.log("un" );
        notify.hide();
        notify.show( "Updating..." );
        let bz = new BioZing();
        await bz.Update( this.props.application.ID, { active: 1 } );
        this.madeActive( true );
        notify.hide();
    }

    madeActive = ( active ) => {

        if ( this.props.callback && this.props.callback.ApplicationUpdated )
        {
            this.props.callback.ApplicationUpdated( this.props.application, { active: active} );
        }
    }
    view = () => {

        window.open( new Urls().ApplicationDisplayUrl() + this.props.application.Link, "_blank" );
    }

    render() {


//console.log( "app", this.props.application)

        if ( ! this.props.application )
        {
            return null;
        }

        // let remaining = this.props.application.Submissions - (this.props.application.Shortlisted + this.props.application.Declined + this.props.application.Interviewing);

        let hasLink = true;
        let fullLink = "";
        let link = this.props.application.Link;
        if ( ! link || link.length === 0 )
        {
            link = "Not yet published";
            hasLink = false;
        }
        else
        {
            fullLink = new Urls().ApplicationDisplayUrl() + link;
        }

        let isActive = this.props.application.Active;

        let contentClass = "application_details_content";
        if ( ! hasLink )
        {
            contentClass += ' no_link ';
        }


                return (

                    <div className="application_details">
                        <div className={contentClass}>
                            <div className="app_details_info">
                                <div className="app_details_link">
                                    <span className="fw600">Link:</span><span className="fw400"> {link}</span><Span width={10} />
                                        {hasLink && <CopyToClipboardButton text={fullLink} copiedMsg="Link copied" title="Copy link to clipboard" />}
                                </div>
                                <div className="app_details_buttons">
                                    <span><Span width={5} /><div className="action_button sm" onClick={this.edit}>Edit</div><Span width={5} /></span>
                                    {(isActive && ! hasLink) && <span><Span width={5} /><div className="action_button sm"  onClick={this.post}>Publish</div><Span width={5} /></span>}

                                    {(isActive && fullLink.length > 0) && <span><Span width={5} /><div className="action_button xxsm quiet" onClick={this.view}>View</div><Span width={5} /></span>}
                                    {isActive &&
                                                <span>
                                                    <Span width={5} />
                                                        {hasLink && <div className="action_button xxsm quiet" onClick={this.archive}>Archive</div>}
                                                        {! hasLink && <div className="action_button xxsm quiet" onClick={this.archive}>Delete</div>}
                                                    <Span width={5} />
                                                </span>
                                        }
                                        {! isActive &&
                                                <span>
                                                    <Span width={5} />
                                                        <div className="action_button xxsm quiet" onClick={this.unarchive}>Unarchive</div>
                                                    <Span width={5} />
                                                </span>
                                        }

                                </div>
                            </div>


                        </div>
                        {this.state.isPosting &&
                            <div className="application_full_display_posting">
                                <div className="application_full_display_posting_content"><Loader type="Oval" color="#FFFFFF" height={15} width={15} /><div className="application_full_display_posting_content_msg"> Publishing...</div></div>
                            </div>
                        }
                    </div>
                )

        }
    }
