import { faCaretDown, faCaretUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable from 'react-data-table-component';
import { ApplicationStatus } from '../classes/ApplicationsSummary';
import BioZing from '../classes/BioZing';
import Broadcast from '../classes/Broadcast';
import Globals from '../classes/Globals';
import IOStatus from '../classes/IOStatus';
import Urls from '../classes/Urls';
import User from '../classes/User';
import SearchBox from '../ui/SearchBox';
import ChangeStatusTypeMenu from './ChangeStatusTypeMenu';
import SubmissionStatusSlug from './SubmissionStatusSlug';
import Delete from './Icons/Delete';
import eventBus, { eventBusEvents } from '../classes/EventBus';


class StatusPickerButton extends React.Component {


  constructor( props )
  {
    super( props );

    this.state = { open: this.props.open === true }
  }

  toggleMenu = () => {
    this.setState( { open: ! this.state.open} );
  }

  CloseStatusTypeMenu = () => {

    this.setState( { open: false } );
  }

  StatusChangeTypePicked = ( type ) => {

    if ( this.props.callback && this.props.callback.StatusChangeTypePicked )
    {
      this.props.callback.StatusChangeTypePicked(type);
    }

  }

  render() {

    let icon = this.state.open ? faCaretUp : faCaretDown;

    let title = "All Candidates";

    switch ( this.props.type )
    {
      case ApplicationStatus.UNREAD:
      case ApplicationStatus.DECLINED:
      case ApplicationStatus.INTERVIEWING:
      case ApplicationStatus.SHORTLISTED:
      case ApplicationStatus.CLIENT_ACCEPTED:
      case ApplicationStatus.CLIENT_DECLINED:

        title = <SubmissionStatusSlug type={this.props.type} inline={true} size="large" />;
        break;

      default: break;
    }

    let button = <div onClick={this.toggleMenu} className="status_picker_button"><div className="status_drop_down_title">{title}</div>  <FontAwesomeIcon className="status_drop_down_button" icon={icon} ></FontAwesomeIcon></div>

    return (
            <>
            {button}
            {this.state.open && <ChangeStatusTypeMenu callback={this} />}
            </>
    )
  }
}


class InterviewDisplayDate extends React.Component {

  formatDate = ( date ) =>
  {
    let d = new Date( date * 1000 ).toLocaleDateString(undefined, {
      day : 'numeric',
      month : 'short'} );
    return d;
  }


  render() {
    // console.log( this.props.date );
    if (  this.props.status !== ApplicationStatus.INTERVIEWING )
    {
      return <div></div>;
    }
    if ( ! this.props.date || this.props.date <= 0  )
    {
        // interview date not set yet
        return <div>Set date</div>
      }


    let date = this.formatDate( this.props.date );
    return <div>{date}</div>

  }
}

export default class SubmissionsDisplay extends React.Component {


    UNFILTERED = -1;
    allSubmissions = [];


    columns = [
      {
        name: <div className="submissions_table_header">Applicant</div>,
        selector: 'applicant_name',
        sortable: true,
      },
      {
        name: <div className="submissions_table_header">Status</div>,
        //name: <StatusPickerButton callback={this} />,
        selector: 'status',
        cell: row => <SubmissionStatusSlug type={row.status} count={-1} />,
        sortable: true,
        center: true,
        width: '110px',
      }, /*
      {
        name: <div className="submissions_table_header">Interview</div>,
        selector: 'interview_date',
        sortable: true,
        grow: 0,
        width: '100px',
        format: row => <InterviewDisplayDate date={row.interview_date} status={row.status} interview_status={row.interview_status} />,
        center: true
      }, */
      {
        name: <div className="submissions_table_header">Received</div>,
        selector: 'date_submitted',
        sortable: true,
        grow: 0,
        width: '90px',
        format: row => this.formatDate(row.date_submitted),
        center: true
      },
      {
        name: "",
        sortable: false,
        selector: (row) => { return ( <div onClick={() => this.showSubmission(row.id)} title="View submission" className="submissions_display_link_icon" ><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon></div>  )},
        grow: 0,
        width: '40px',
        center: true
      },{
        name: "",
        sortable: false,
        selector: (row) => { return ( <div onClick={() => this.deleteSubmission(row.id, row.applicant_name)} title="Delete submission" className="submissions_display_delete_icon" ><Delete /></div>  )},
        grow: 0,
        width: '40px',
        center: true
      }
    ];

    customStyles = {
        rows: {
          style: {
            minHeight: '36px', // override the row height
            fontSize: '15px'
          }
        },
        headCells: {
          style: {
              paddingLeft: '8px',
              paddingRight: '8px',
              fontSize: '15px',
          },
        },
        headRow:{
            style: {
                minHeight: '18px',
                background: '#e8e8e8',
                borderTopRightRadius: '6px',
                borderTopLeftRadius: '6px',
                paddingTop: '8px',
                paddingBottom: '8px',
                fontSize: '15px'

            }
        },
        cells: {
          style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
          },
        },
        pagination: {
            style: {
                minHeight: '0'
            }
        }
      };


    formatDate = ( date ) =>
    {
      let d = new Date( date * 1000 ).toLocaleDateString(undefined, {
        day : 'numeric',
        month : 'short'} );
      return d;
    }

    constructor( props )
    {
      super( props );

      this.state = { loading: true,
                        submissions: [], // fltered list of submissions by status
                        submissionFilter: this.UNFILTERED
                      }

    }

    componentDidMount() {

      let broadcast = Globals.Broadcast.Channel();
      broadcast.addEventListener('message', this.handleBroadcastMessage);

      this.removeEventBus();
      eventBus.on( eventBusEvents.SUBMISSION_DELETED, this.submissionDeleted )
      this.loadSubmissions( this.props.post_link , {} );

      // TESTING
      /*
      if ( Utils.DevMode() )
      {
        setTimeout( () => {
          console.log( "sending msg" );
          this.handleBroadcastMessage( { type: Broadcast.SUBMISSION_STATUS_UPDATED, "id": 27, "status": 3 } );
        }, 10000 )
      }
      */
    }

    componentWillUnmount() {

      this.removeEventBus();

      let broadcast = Globals.Broadcast.Channel();
      broadcast.removeEventListener('message', this.handleBroadcastMessage);
    }

    removeEventBus = () => {
      eventBus.remove( eventBusEvents.SUBMISSION_DELETED, this.submissionDeleted )
    }

    submissionDeleted = ( data ) => {

      if ( data && data.id > 0 )
      {
        let subs = [];
        for ( var i = 0 ; i < this.allSubmissions.length; i++ )
        {
            let sub = this.allSubmissions[i];
            if ( parseInt(sub.id) !== parseInt(data.id) )
            {
              subs.push( sub );
              // remove all submissions and reload
              // data table doesn't reload when status changes, only happens if id is changed??
            }
        }
        this.allSubmissions = subs;
        this.setState( { submissions: [] }, () => {
          this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions ) } );
        })
      }
    }



    // received msg from another tab
    handleBroadcastMessage = ( msg ) => {

      if ( ! msg )
      {
        return;
      }

      switch( msg.type )
      {

        case Broadcast.SUBMISSION_STATUS_UPDATED:
        {
          let id = msg.id;
          let status = msg.status;
          if ( ! ApplicationStatus.IsValid( status ) || isNaN( id ) )
          {
            return;
          }

          for ( var i = 0 ; i < this.allSubmissions.length; i++ )
          {
            let sub = this.allSubmissions[i];
            if ( parseInt(sub.id) === parseInt(id) )
            {
              sub.status = status;
              // remove all submissions and reload
              // data table doesn't reload when status changes, only happens if id is changed??
              this.setState( { submissions: [] }, () => {
                this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions ) } );
              })
              return;
            }
          }
        }
          break;

          case Broadcast.SUBMISSION_INTERVIEW_UPDATED:
          {
            let id = msg.id;
            let interview_id = msg.interview_id;
            let interview_status = msg.interview_status;
            let interview_date = msg.interview_date;

            for ( i = 0 ; i < this.allSubmissions.length; i++ )
            {
              let sub = this.allSubmissions[i];
              if ( parseInt(sub.id) === parseInt(id) )
              {
                sub.InterviewID = interview_id;
                sub.InterviewDate = interview_date;
                sub.InterviewStatus = interview_status;

                // remove all submissions and reload
                // data table doesn't reload when status changes, only happens if id is changed??
                this.setState( { submissions: [] }, () => {
                  this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions ) } );
                })
                return;
              }
            }
          }
            break;

          default: break;
      }
    }


   loadSubmissions = async ( post_link, options ) => {
        let bz = new BioZing();
        let res = await bz.LoadSubmissions( post_link, options );
        if ( res.result === IOStatus.OK )
        {
            this.allSubmissions = res.submissions;
            this.setState( { loading: false, submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions ) } );
            return;
        }
    }

    deleteSubmission = (id, applicant_name ) => {

      if ( id && id > 0 )
      {
        eventBus.dispatch( eventBusEvents.DELETE_SUBMISSION, { id: id, applicant_name: applicant_name } )
      }
    }


    archiveSubmission = (id, applicant_name ) => {

      if ( id && id > 0 )
      {
        eventBus.dispatch( eventBusEvents.ARCHIVE_SUBMISSION, { id: id, applicant_name: applicant_name } )
      }
    }


    showSubmission = (id) => {
      if ( id && id > 0 )
      {
        let user = User.CurrentUser();

        window.open( new Urls().ViewSubmissionUrl() + id + "?token=" + user.UserToken, "_blank" );
      }
    }

    StatusChangeTypePicked = ( type ) => {

      this.setState( { submissions: this.filterSubmissions( parseInt(type), this.allSubmissions, "" ), submissionFilter: parseInt( type ) } );
    }

    filterSubmissions = ( filter, subs, search ) => {

      if ( ! subs )
      {
        return [];
      }

      let doSearch = search && search.length > 0;
      let searchTerm = "";
      if ( doSearch )
      {
        // TODO check against non latin characters
        searchTerm = search.toLowerCase();
      }


      if ( filter === this.UNFILTERED && ! doSearch )
      {
        return subs;
      }

      let filtered = [];
      for ( var i = 0; i < subs.length; i++ )
      {
        let sub = subs[i];
        if ( sub.status === filter || filter === this.UNFILTERED || ( filter === ApplicationStatus.DECLINED && sub.status === ApplicationStatus.CLIENT_DECLINED ) )
        {
          if ( doSearch )
          {
            if ( sub.applicant_name && sub.applicant_name.toLowerCase().indexOf( searchTerm ) !== -1 )
            {
              filtered.push( Object.assign( {}, sub ) );
            }
          }
          else
          {
            filtered.push( Object.assign( {}, sub ) );
          }
        }
      }
      return filtered;
    }


    doSearch = ( txt ) => {
      this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, txt ) } );
    }

    render() {

        let noSubmisisons = <div style={{paddingTop: '20px'}}>No submissions to display</div>
        let progress = <div style={{paddingTop: '20px'}}>Loading submissions...</div>

        return (

            <div className="submissions_holder">
                <div className="submissions_header">
                    <StatusPickerButton callback={this} type={this.state.submissionFilter} />
                    {false && <div className="submissions_title">Candidates</div>}

                    <div className="submissions_search"><SearchBox onSearch={this.doSearch} immediate={true} /></div>
                </div>
                <div className="submissions_list">

                <DataTable
                    progressPending={this.state.loading}
                    title=""
                    noHeader={true}
                    pagination={true}
                    columns={this.columns}
                    customStyles={this.customStyles}
                    data={this.state.submissions}
                    noDataComponent={noSubmisisons}
                    progressComponent={progress}
                  />
                </div>
            </div>
        )
    }
}
