import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import GenericBusyOptions from '../classes/GenericBusyOptions'
import eventBus, { eventBusEvents } from '../classes/EventBus';


export default function GenericBusy( props ) {

    const close = () => {
        eventBus.dispatch( eventBusEvents.GENERIC_BUSY, new GenericBusyOptions() )
    }


    if (! props.options.Show ) return null;

    return ( <div className='generic_busy_mask' onClick={close}>
                    <div className='generic_busy_content'>
                        <Loader type="Bars" color="#FFFFFF" height={30} width={60} />
                        {props.options.Msg.length > 0 && <div className='generic_busy_msg'>{props.options.Msg}</div> }
                    </div>
            </div>)
}