import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import BioZing from '../classes/BioZing';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import IOStatus from '../classes/IOStatus';
import eventBus, { eventBusEvents } from '../classes/EventBus';
import Utils from '../classes/Utils';
import CloseCross from './Icons/CloseCross'
import { Checkbox} from 'pretty-checkbox-react';
import '../../css/pretty-checkbox.css'
import Spacer from '../ui/Spacer';
import { browserVersion, fullBrowserVersion, isAndroid, isChrome, isDesktop, isIOS, isMobile, isSafari } from 'react-device-detect';
import User from '../classes/User';
import DashboardNotification from '../classes/DashboardNotification';
import { store } from '../../index';
import * as userActions from '../../actions/user-actions'
import Urls from '../classes/Urls';
import superagent from 'superagent'
import Globals from '../classes/Globals';
import { NotificationsType } from "../classes/NotificationTypes";
import ShareIOS from './Icons/ShareIOS';

export default function NotificationsOptionsDialog( props ) {

    let [mounted, setMounted] = useState( false );
    let [isBusy, setIsBusy] = useState( false );
    let [errorMsg, setErrorMsg] = useState( "" );
    let [ checkedList, setCheckedList] = useState( 0 );
    let [permissionStatus, setPermissionStatus] = useState( "default" );


    useEffect( () => {
        if( ! mounted )
        {
            setMounted( true );
        }
        let user = User.CurrentUser();

        if ( isDesktop )
        {
        setCheckedList( user.DesktopNotifications );
        }
        if ( isMobile )
        {
            setCheckedList( user.MobileNotifications );
        }
        setPermissionStatus( checkUserPermission() );
    }, [] )

    const updateCeckedList = ( checked, flag ) => {
        if ( checked )
        {
            setCheckedList( checkedList | flag)
        }
        else
        {
            setCheckedList( checkedList ^ flag )
        }
    }

    const checkChanged =( e, type ) => {
        let checked = e.currentTarget.checked;
        updateCeckedList( checked, type );
    }

    const close = () => {
        if ( props && props.callback && props.callback.closeNotificationOptionsDialog )
        {
            props.callback.closeNotificationOptionsDialog()
        }
    }


    const requestPushPermission = () => {

        window.Notification.requestPermission().then((permission) => {
            // console.log( permission )
            doSave();
            startPush();
        } )
    }

    const startPush = () => {

        if ( checkUserPermission() == "granted" )
        {
            Globals.FirebaseInstance.StartPushNotifications( checkedList );
        }
    }

    const stopPush = () => {
        Globals.FirebaseInstance.StopPushNotifications();
    }

    function checkUserPermission() {
        if ( ! window.Notification ) return "denied"
        return Notification.permission
    }

    const save = () => {

        setIsBusy( true );

        if ( NotificationsType.ContainsPush( checkedList ) && permissionStatus == "default" )
        {
            requestPushPermission();
            return;
        }

        if ( NotificationsType.ContainsPush( checkedList ) )
        {
            startPush();
        }
        else
        {
            stopPush();
        }
        doSave();
    }

    const doSave = () => {

        setIsBusy( true );

        let fields = { "DesktopNotifications": checkedList }
        if  (isMobile)
        {
            fields = { "MobileNotifications": checkedList }
        }

        store.dispatch( userActions.updateUser( fields ) )

        let url = new Urls().UpdateUserUrl();
        let user = User.CurrentUser();

        superagent.put( url )
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('x-requested-with', 'XMLHttpRequest')
        .send( fields )
        .auth(user.UserToken, { type: 'bearer' })
        .end((error, response) => {
            //console.log( error, response )
            close();
        } )

    }

    let pushAvailable = Globals.FirebaseInstance.PushMessagingSupported();
    let notSupportedMessage = "This browser does not support push notifications"
    if ( ! pushAvailable )
    {
        if ( isMobile )
        {
            if ( isAndroid )
            {
                if ( ! isChrome )
                {
                    notSupportedMessage = "This browser does not support push notifications, consider using Chrome instead."
                }
            }
            if ( isIOS )
            {
                if ( ! isSafari )
                {
                    notSupportedMessage = "This browser does not support push notifications, please open in Safari to receive notifications."
                }
                else
                {
                    //if ('Notification' in window )
                   // {
                        if (window.matchMedia('(display-mode: standalone)').matches)
                        {
                            // console.log( "in pwa")
                            // this should work, can't give any more information
                        }
                        else
                        {
                            notSupportedMessage = <>You need to add this page to your Home Screen to receive notifications.<br />Open the browser Actions menu <ShareIOS size={18} /><br />and choose 'Add to Home Screen'.</>
                        }
                   // }
                }
            }
        }
    }
    let pushDenied = permissionStatus == "denied";

    let submitLabel = <>SAVE</>
    if ( isBusy )
    {
        submitLabel = <div className='notifications_options_busy'><Loader type="Bars" color="#FFFFFF" height={30} width={15} /></div>
    }

    // console.log( isMobile, isSafari, isAndroid, isIOS, browserVersion, fullBrowserVersion );

    return   (

        <CSSTransition
            in={mounted}
            timeout={300} unmountOnExit classNames="dialog-animate" >
            <div className='dialog'>
                <div className='dialog_mask'></div>
                    <div className='dialog_content notifcation_options'>
                        <div className='dialog_header'>
                            <div className='dialog_title'>Notifications</div>
                            <div className='dialog_close_button' onClick={close}><CloseCross /></div>
                        </div>
                        <div className='dialog_body'>
                            <Spacer height={15} />
                            <div className='notification_options'>
                                <div className='notification_options_title'>Dashboard notifications</div>
                                <div className='notification_options_description'>Show these notifications on your dashboard</div>
                                <div className='notification_options_checks'>
                                    <Checkbox onChange={(e) => checkChanged( e, NotificationsType.DASHBOARD_ALL_CLIENT )} checked={Utils.FlagIsSet(checkedList, NotificationsType.DASHBOARD_ALL_CLIENT)} animation="smooth"  color="success-o" style={{ xfontSize: 14 }}>Clients</Checkbox>
                                    <Checkbox onChange={(e) => checkChanged( e, NotificationsType.DASHBOARD_ALL_CANDIDATE )} checked={Utils.FlagIsSet(checkedList, NotificationsType.DASHBOARD_ALL_CANDIDATE)}  animation="smooth"  color="success-o" style={{ xfontSize: 14 }}>Candidates</Checkbox>
                                </div>
                            </div>
                            <Spacer height={20} />
                            <div className='notification_options'>
                                <div className='notification_options_title'>Push notifications</div>
                                {pushAvailable && <>
                                    {pushDenied && <><div className='notification_options_push_unavailable'>Push notifications for this site are currently disabled. Permissions will need to be reset to receive notificatons.</div></> }
                                    {! pushDenied &&
                                    <>
                                    <div className='notification_options_description'>Send these push notifications to this browser</div>
                                    <div className='notification_options_checks'>
                                        <Checkbox  onChange={(e) => checkChanged( e, NotificationsType.PUSH_ALL_CLIENT )} checked={Utils.FlagIsSet(checkedList, NotificationsType.PUSH_ALL_CLIENT)} animation="smooth"  color="success-o" style={{ xfontSize: 14 }}>Clients</Checkbox>
                                        <Checkbox onChange={(e) => checkChanged( e, NotificationsType.PUSH_ALL_CANDIDATE )} checked={Utils.FlagIsSet(checkedList, NotificationsType.PUSH_ALL_CANDIDATE)} animation="smooth"  color="success-o" style={{ xfontSize: 14 }}>Candidates</Checkbox>
                                    </div>
                                    </>}
                                </>}
                                {! pushAvailable && <div className='notification_options_push_unavailable'>{notSupportedMessage}</div>}
                            </div>
                            {isDesktop && <div className='notification_options_phone_description'>To receive Push notifications on your phone, open this page in Safari on Apple or Chrome on Android, and follow the instructions.</div>}
                            <div className='notification_options_phone_description'>Please note you can only send Push notifications to one phone and one dektop.</div>
                        </div>

                        <div className='dialog_footer'>
                            <div className='dialog_footer_button action_button sm notifications_submit_button' onClick={save}>{submitLabel}</div>
                            <div className='dialog_footer_button action_button sm quiet dialog_cancel_button' onClick={close}>Cancel</div>
                        </div>

                    </div>
            </div>
        </CSSTransition>
    )
}
