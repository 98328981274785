import 'firebase/analytics';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
import React from 'react';
import { Redirect } from "react-router-dom";
import * as shortlistActions from '../../actions/shorlisted-actions';
import * as notificationActions from '../../actions/notifications-actions'
import { store } from '../../index';
import Globals from '../classes/Globals';
import User from "../classes/User";
import Utils from '../classes/Utils';
import DashboardNotification from '../classes/DashboardNotification';
import FirebaseMessaging from './FirebaseMessaging-custom-sw';





// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
    apiKey: "AIzaSyC9ZavdeRid-qxciHuLsaCwJEvdjS0_Lbc",
    authDomain: "recruit-zing.firebaseapp.com",
    databaseURL: "https://recruit-zing.firebaseio.com",
    projectId: "recruit-zing",
    storageBucket: "recruit-zing.appspot.com",
    messagingSenderId: "363130057479",
    appId: "1:363130057479:web:8e703f93b138ae921e8ba2",
    measurementId: "G-80CWMHW8F2"
};
const vapidKey = "BBXGZZ3MkGqoRl4j8iGfKShDHbmhLzi0YhxSBEM32zEcFbpM_z2cQbEd5TlqeL4m0_LrT0n5wpLOjtRVFMWqkiI";
let messaging = null;

/*
var configDev = {
  apiKey: "AIzaSyC9ZavdeRid-qxciHuLsaCwJEvdjS0_Lbc",
  authDomain: "recruit-zing.firebaseapp.com",
  databaseURL: "https://recruit-zing.firebaseio.com",
  projectId: "recruit-zing",
  storageBucket: "recruit-zing.appspot.com",
  messagingSenderId: "363130057479",
  appId: "1:363130057479:web:8e703f93b138ae921e8ba2",
  measurementId: "G-80CWMHW8F2"
};
*/

class Firebase extends React.Component {


  static FirebaseInstance = null;
  static FirebaseApp = null;

  Callback = null;

  // listen to responses from client
  clientResponseListener = null;

  constructor(props) {
    super( props );

    let currentConfig = config;
    if ( Utils.DevMode() )
    {
      // dev using test database to check rules
      // for telezingdev
     //  currentConfig = configDev;
    }

    // console.log( "Firebase init" );

    app.initializeApp(currentConfig);

    app.analytics();
    app.firestore();

    app.auth();
    if ( app.messaging.isSupported() )
    {
      app.messaging();
    }

    Globals.FirebaseApp = app;
    Globals.FirebaseInstance = this;

    // not doing anything about success or failure
    app.auth().setPersistence(app.auth.Auth.Persistence.SESSION);
    /*
      .then(() => {
        })
      .catch((error) => {
      });
      */

    app.auth().onAuthStateChanged(function(user) {
      if (user) {


        // User is signed in.
        // console.log( user )
        Globals.FirebaseInstance.clearListeners();
        if ( ! user.isAnonymous )
        {
            Globals.FirebaseInstance.AddShortlistListeners();
        }

        if ( Globals.FirebaseInstance.PushMessagingSupported() )
        {
          Globals.FirebaseInstance.messaging = new FirebaseMessaging(app, vapidKey, false );
         //  Globals.FirebaseInstance.StartPushNotifications();
        }

/*
        let newUser = new User();

        newUser.Name = "";
        newUser.IsAnonymous = user.isAnonymous;
        newUser.Identifier = user.uid;
        newUser.LoggedInToFirebase = true;
        if ( Utils.HasString( user.email) )
        {
          newUser.Email = user.email;
        }
        newUser.EmailVerified = (user.emailVerified === true);

        // store.dispatch( userActions.replaceUser( newUser) );

        this.state = { redirect: null };

        if ( newUser.IsAnonymous )
        {
          Globals.Storage.RemoveLocal( Globals.FIREBASE_HAS_ACCOUNT );
        }
        else
        {
          Globals.Storage.StoreLocal( Globals.FIREBASE_HAS_ACCOUNT, "1" );
        }

        user.getIdTokenResult(true).then((idTokenResult) => {

         // console.log( 'token result',  idTokenResult );
        //  console.log( idTokenResult.claims );
        //  console.log( user.uid );


          let url = new Urls().UserTokenUrl();
          superagent.post( url )
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('x-requested-with', 'XMLHttpRequest')
          .send({ "idToken": idTokenResult} )
        //  .use(AuthRequest.AddAuthHeaders)

        .end((error, response) => {

          if ( ! error && response && response.body )
          {

            if ( response.body.successful && response.body.token && response.body.token.length > 0 )
            {
              newUser.UserToken = response.body.token;

              //let updates = { UserToken: response.body.token };

              if ( idTokenResult.claims )
              {
                if ( idTokenResult.claims.email && idTokenResult.claims.email.length > 0 )
                {
                //  updates["Email"] = idTokenResult.claims.email;
                  newUser.Email = idTokenResult.claims.email;
                }
                if ( idTokenResult.claims.email_verified === true )
                {
                  newUser.EmailVerified = idTokenResult.claims.email_verified;
                  // updates["EmailVerified"] = true;
                }
              }

              store.dispatch( userActions.replaceUser( newUser) );

              // store.dispatch( userActions.updateUser( updates ) );
              if ( Globals.FirebaseInstance.Callback && Globals.FirebaseInstance.Callback.AuthStatusChanged )
              {
                Globals.FirebaseInstance.Callback.AuthStatusChanged();
              }
            }
          }
        });

        });
        // ...
        */
      } else {
        // User is signed out.
        // ...
       // console.log( "signed out" );
        // store.dispatch( userActions.replaceUser( new User() ) );
        Globals.FirebaseInstance.clearListeners();

        Globals.Storage.RemoveLocal( Globals.FIREBASE_HAS_ACCOUNT);
        if ( Globals.FirebaseInstance.Callback && Globals.FirebaseInstance.Callback.AuthStatusChanged )
        {
          Globals.FirebaseInstance.Callback.AuthStatusChanged();
        }
      }
      // ...

      // store.dispatch( dialogActions.closeLogIn() );
    });
  }

  clearListeners = () =>
  {
    if ( this.clientResponseListener )
      {
        this.clientResponseListener();
        this.clientResponseListener = null;
      }
  }

    AddShortlistListeners = () =>
    {

      this.clearListeners();
      let db = Globals.FirebaseApp.firestore();

      let user = User.CurrentUser();

      if ( ! user.FirebaseUid || user.FirebaseUid.length === 0 )
      {
        return;
      }

      let This = this;

      let path = "notifications/" + user.FirebaseUid + "/submissions"
      this.clientResponseListener = db.collection( path )
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {

          let data = change.doc.data();
          let uid = change.doc.id;
          // console.log( "DATA", data );
          switch( change.type )
          {
            case "modified":
            case "added":

              let sub = { submission_id: uid, status: parseInt(data.s) }
              let date = new Date();
              if ( data.d )
              {
                date = data.d.toDate();
              }
              sub.date = date.getTime()/1000;
              data.submission_id = uid;
              data.date = data.d.toDate().getTime();
              // update interface
              store.dispatch( shortlistActions.updateStatus( sub ));
              // This.deleteSubmissionNotifications( uid );
              // create a notification
              let notification = DashboardNotification.Create( uid, data );

              if ( notification )
              {
                store.dispatch( notificationActions.addNotification( notification ) );
              }
              //console.log( notification );
              //console.log( sub )
              break;

              default: break;
          }
        });
      });
  }

  deleteSubmissionNotifications = ( submission_id ) => {

    let db = Globals.FirebaseApp.firestore();
    let user = User.CurrentUser();

    if ( ! user.FirebaseUid || user.FirebaseUid.length === 0 )
    {
      return;
    }
    if ( ! submission_id || isNaN( submission_id ) )
    {
      return;
    }
    let doc = db.collection("notifications/" + user.FirebaseUid + "/submissions/").doc( submission_id );
    if ( doc )
    {
      doc.delete();
    }
  }



    SignOut = () =>
    {
     // console.log( "sign out" );
        app.auth().signOut();
    }

  LogInAnonymously = () =>
  {
    // console.log( "anon log in" );
    app.auth().signInAnonymously().catch(function(error) {
    // Handle Errors here.
    //var errorCode = error.code;
    //var errorMessage = error.message;
    // ...
    })
  }

  LogInWithCustomToken = ( token ) => {

    app.auth().signOut().then(() => {
        app.auth().signInWithCustomToken(token)
        .then((userCredential) => {
          // Signed in
         // console.log( userCredential )
          // ...
          let user = User.CurrentUser();
          user.FirebaseUid = userCredential.user.uid;
        })
        .catch((error) => {
//          var errorCode = error.code;
  //        var errorMessage = error.message;

          // console.log( errorCode, errorMessage );
          // ...
        })
      }
    ).catch( (error) => {

    })
  }


  PushMessagingSupported = () => {

    if ( ! app || ! app.messaging || ! app.messaging.isSupported() )
    {
      return false
    }
    return true;
  }


  StartPushNotifications = ( topics ) => {

     // console.log( "fb StartPushNotifications")
      if ( this.messaging )
      {
        this.messaging.StartPushNotifications( topics );
      }
    }
/*
    SubscribeToPushNotifications = ( subscribe ) => {
      if ( this.messaging )
      {
        this.messaging.SubscribeToNotifications( true );
      }
    }
*/
    StopPushNotifications = () => {

      // console.log( "StopPushNotifications", this.messaging )
      // messaging registration my not have taken place if anonymous user, so may need to register it so can unregister it from a previous user
      if ( ! this.messaging )
      {
        if ( Globals.FirebaseInstance.PushMessagingSupported() )
        {
          Globals.FirebaseInstance.messaging = new FirebaseMessaging(app, vapidKey, false );
        }
      }
      if ( this.messaging )
      {
        this.messaging.StopPushNotifications();
      }
    }
/*

  InitPushMessaging = () => {

    if ( ! app.messaging.isSupported() )
    {
      return;
    }
    messaging = app.messaging();

    messaging.getToken( { vapidKey: vapidKey} )
    .then( ( messagingToken ) => {
      if ( messagingToken )
      {
        console.log( "token" , messagingToken );
      }
      else
      {
        console.log('No registration token available. Request permission to generate one.');
      }
    } )
    .catch( ( err ) => {
      console.log('An error occurred while retrieving token. ', err);
    } );

    messaging.onMessage((payload) => {
      console.log('foreground Message received. ', payload);
      // ...

      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        silent: true,
        icon: "./logo192.png"
      };
        const notification = new Notification(notificationTitle, notificationOptions );
    });


  }
*/

  /*
  function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // In many cases once an app has been granted notification permission,
        // it should update its UI reflecting this.
        resetUI();
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }

  function deleteToken() {
    // Delete registration token.
    messaging.getToken().then((currentToken) => {
      messaging.deleteToken(currentToken).then(() => {
        console.log('Token deleted.');
        setTokenSentToServer(false);
        // Once token is deleted update UI.
        resetUI();
      }).catch((err) => {
        console.log('Unable to delete token. ', err);
      });
    }).catch((err) => {
      console.log('Error retrieving registration token. ', err);
      showToken('Error retrieving registration token. ', err);
    });
  }
  */


  componentDidMount() {

  }



    render () {

      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
    }
      return null;

    }

}
export default Firebase;