import Utils from "./Utils";


export class DashboardNotificationType {

    static SYSTEM = "system"; // system gbenerated
    static CLIENT = "client"; // client accepted or declined
    static CANDIDATE = "candidate"; // candidation posted submisison

}


export default class DashboardNotification {

    Type = DashboardNotificationType.SYSTEM;
    Date = new Date();
    SubmissionID = 0;
    Status = 0; // submission status
    ApplicationTitle = ""; // name of application to show recruiter
    CandidateName = "";
    DateStr = "";
    ClientName = "";


    static Create = ( submission_id, data ) => {
        if ( ! data ) return null;

        let dn = new DashboardNotification()

        let date = new Date();
        if ( data.d )
        {
            date = data.d.toDate();
        }
        dn.Date = parseInt(date.getTime()/1000);
        dn.SubmissionID = submission_id;
        dn.ApplicationTitle = Utils.EnsureString( data.a );
        dn.Status = data.s;
        dn.CandidateName = Utils.EnsureString( data.n );
        dn.ClientName = Utils.EnsureString( data.c );
        switch( data.t )
        {
            case "client":

                dn.Type = DashboardNotificationType.CLIENT;
                break;

            case "candidate":

                dn.Type = DashboardNotificationType.CANDIDATE;
                break;
        }
        // data.date = data.d.toDate().getTime();

        dn.DateStr = dn.formatDate( dn.Date );
        return dn;
    }

    formatDate = ( date ) =>
    {
      let d = new Date( date * 1000 ).toLocaleDateString(undefined, {
        day : 'numeric',
        month : 'short'} );
      return d;
    }
}
